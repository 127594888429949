import React, { useState } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import PropTypes from 'prop-types'
import { Logo } from '../../assets'
import { GrClose, GrMenu } from 'react-icons/gr'
import { IoMdNotifications } from 'react-icons/io'

import './navigation.css'

export const NavigationBar = ({ notifications, user }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <Navbar expand="lg" sticky="top">
      <Navbar.Brand href="/">
        <img src={Logo} alt="Darbe Logo" />
      </Navbar.Brand>

      <Nav className="ml-auto">
        <Nav.Link
          style={{ borderBottom: 'none' }}
          href={`/feed/${user._id}/notifications`}
        >
          {notifications > 0 ? (
            <div style={{ position: 'relative' }}>
              <IoMdNotifications color="#263238" size={'1.5em'} />
              <div className="rounded"></div>
            </div>
          ) : (
            <>
              <IoMdNotifications color="#263238" size={'1.5em'} />
            </>
          )}
        </Nav.Link>
      </Nav>
      <Navbar.Toggle
        onClick={() => setExpanded(!expanded)}
        aria-controls="responsive-navbar-nav"
        id="nav-bar-icon"
      >
        {expanded ? (
          <GrClose color="#263238" size={'1.5em'} />
        ) : (
          <GrMenu color="#263238" size={'1.5em'} />
        )}
      </Navbar.Toggle>

      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav className="me-auto">
          <Nav.Link href="/profile">View Profile</Nav.Link>
          <Nav.Link
          // href=''
          >
            My Network
          </Nav.Link>
          <Nav.Link
          // href=''
          >
            Help
          </Nav.Link>
          <Nav.Link href="/privacyPolicy">Privacy Policy</Nav.Link>
          <Nav.Link
            // href=''
            id="no-bottom"
          >
            Log Out
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

NavigationBar.propTypes = {
  notifications: PropTypes.number,
  user: PropTypes.object,
}
