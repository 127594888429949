import React from 'react'
import { backArrow } from '../../../../assets'
import './index.css'

export default function Conversation() {
  return (
    <div>
      <div className="convoCont">
        <img src={backArrow} />
        <div>
          <img src="https://via.placeholder.com/50" className="chatProfile" />
        </div>
        <div className="entityCont">
          <p className="entityName">*Entity name here*</p>
          <p className="entityDesc">*Entity description here*</p>
        </div>
      </div>
    </div>
  )
}
