import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BACK_URL || 'http://localhost:3001'

export default {
  subscribeEmail: (jsonEmail) => {
    console.log(jsonEmail)
    return axios.put(BASE_URL + '/v1/api/subscribe/new', jsonEmail)
  },
  unsubscribe: (email, reason) => {
    return axios.put(
      BASE_URL + '/v1/api/subscribe/unsubscribe/' + email,
      reason
    )
  },
  googleOAuth: () => {
    // Not a fan of this, but worked around CORS, if other solutions work I would like to hear them :)
    window.location.href = BASE_URL + '/v1/api/oauth/google'
  },
  appleOAuth: () => {
    window.location.href = BASE_URL + '/v1/api/oauth/apple'
  },
  login: async (email, password) => {
    try {
      const token = await axios.post(BASE_URL + '/v1/api/login', {
        email,
        password,
      })
      return token
    } catch (e) {
      return e
    }
  },
  register: async (data) => {
    try {
      const token = await axios.post(BASE_URL + '/v1/api/signup', { ...data })
      return token
    } catch (e) {
      return e
    }
  },
  searchUsers: async (searchTerm) => {
    try {
      if (searchTerm.length > 2) {
        const shallowList = await axios.get(
          BASE_URL + `/v1/api/individual/search/${searchTerm}`
        )

        return shallowList.data
      }
    } catch (e) {
      return e
    }
  },
}
