import React, { useState } from 'react'
// import dbAPI from '../../util/dbAPI'
import { copyLinkIcon, shareIcon, clickedShareIcon } from '../../assets'
import './styles/ShareModalStyles.css'
export const ShareModal = () => {
  const [shareIconSrcNewsFeed, setShareIconSrcNewsFeed] = useState(shareIcon)
  const [shareIconSrcWithFriend, setShareIconSrcWithFriend] =
    useState(shareIcon)
  const [shareIconSrcViaEmail, setShareIconSrcViaEmail] = useState(shareIcon)
  const [shareIconSrcViaText, setShareIconSrcViaText] = useState(shareIcon)
  const changeIcon = (evt) => {
    const whichIcon = evt.target.id
    switch (whichIcon) {
      case 'newsFeed':
        if (shareIconSrcNewsFeed === shareIcon) {
          setShareIconSrcNewsFeed(clickedShareIcon)
        } else {
          setShareIconSrcNewsFeed(shareIcon)
        }
        // TODO: API Call here and redirects if needed
        break
      case 'withFriend':
        if (shareIconSrcWithFriend === shareIcon) {
          setShareIconSrcWithFriend(clickedShareIcon)
        } else {
          setShareIconSrcWithFriend(shareIcon)
        }
        // TODO: API Call here and redirects if needed
        break
      case 'viaEmail':
        if (shareIconSrcViaEmail === shareIcon) {
          setShareIconSrcViaEmail(clickedShareIcon)
        } else {
          setShareIconSrcViaEmail(shareIcon)
        }
        // TODO: API Call here and redirects if needed
        break
      case 'viaText':
        // TODO: Should only be seen if mobile browser, investigate and open new ticket
        if (shareIconSrcViaText === shareIcon) {
          setShareIconSrcViaText(clickedShareIcon)
        } else {
          setShareIconSrcViaText(shareIcon)
        }
        // TODO: API Call here and redirects if needed
        break
      default:
        // TODO: API Call here and redirects if needed
        navigator.clipboard.writeText(
          'URL TO POST WILL GO HERE, NEEDS TICKET WORK/POSTS DONE'
        )
        break
    }
  }
  return (
    <div className="shareModal">
      <div className="shareNewsFeed shareLinks">
        <img
          src={shareIconSrcNewsFeed}
          alt="shareIcon"
          id="newsFeed"
          className="shareIcon"
          onClick={changeIcon}
        ></img>
        <span>Share to News Feed</span>
      </div>
      <div className="shareWithFriend shareLinks">
        <img
          src={shareIconSrcWithFriend}
          alt="shareIcon"
          id="withFriend"
          className="shareIcon"
          onClick={changeIcon}
        ></img>
        <span>Share with Friend</span>
      </div>
      <div className="shareViaEmail shareLinks">
        <img
          src={shareIconSrcViaEmail}
          alt="shareIcon"
          id="viaEmail"
          className="shareIcon"
          onClick={changeIcon}
        ></img>
        <span>Share via Email</span>
      </div>
      <div className="shareViaText shareLinks">
        <img
          src={shareIconSrcViaText}
          alt="shareIcon"
          id="viaText"
          className="shareIcon"
          onClick={changeIcon}
        ></img>
        <span>Share via Text Message</span>
      </div>
      <div className="shareCopyLink shareLinks">
        <img
          src={copyLinkIcon}
          alt="copyLinkIcon"
          id="copyLink"
          className="copyLinkIcon"
          onClick={changeIcon}
        ></img>
        <span>Copy Link</span>
      </div>
    </div>
  )
}
export default ShareModal
