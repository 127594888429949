import Comment from './images/Comment.png'
import Event from './images/Event.png'
import Like from './images/Like.png'
import Share from './images/Share.png'

export const notificationTypesEnum = {
  like: Like,
  comment: Comment,
  share: Share,
  event: Event,
}

export const notificationTitleEnum = {
  like: 'liked your post',
  comment: 'commented on your post',
  share: 'shared your post',
  event: 'shared and event',
}
