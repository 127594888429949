import React from 'react'
import { CircleIcon, CommentIcon, LikeIcon, ShareIcon } from '../IconComponents'
import PropTypes from 'prop-types'
import IconButton from '../IconButton/IconButton'
import './PostInteraction.css'

const PostInteraction = ({
  liked = false,
  likes = 0,
  comments = 0,
  handleClick,
}) => {
  return (
    <div className="postInteraction">
      <div className="postInteraction-info">
        {/* Likes count  */}
        <div
          className="postInteraction-likes"
          // TODO: REVISE IF NEEDED LATER
          // onClick={(evt) => handleClick(evt, 'LIKES')}
          >
          <div className="postInteraction-likes-icon-wrapper">
            <div className="postInteraction-likes-circle">
              <CircleIcon />
            </div>
            <div className="postInteraction-likes-icon">
              <LikeIcon />
            </div>
          </div>
          <p>{likes}</p>
        </div>

        {/* Comments count */}
        <div className="postInteraction-comments">
          <p onClick={(evt) => handleClick(evt, 'COMMENTS')}>
            {`${comments} 
            ${comments > 1 || comments === 0 ? 'comments' : 'comment'}`}
          </p>
        </div>
      </div>
      <div className="postInteraction-buttons">
        {/* Like button */}
        <IconButton
          text={liked ? 'Liked' : 'Like'}
          icon={<LikeIcon />}
          handleClick={handleClick.likesItem}
          active={liked}
          type="LIKE"
        />

        {/* Comment button */}
        <IconButton
          text="Comment"
          icon={<CommentIcon />}
          handleClick={handleClick.commentsItem}
          type="COMMENT"
        />

        {/* Share button */}
        <IconButton
          text="Share"
          icon={<ShareIcon />}
          handleClick={handleClick.shareItem}
          type="SHARE"
        />
      </div>
    </div>
  )
}

PostInteraction.propTypes = {
  likes: PropTypes.number,
  liked: PropTypes.bool,
  comments: PropTypes.number,
  handleClick: PropTypes.object,
}

export default PostInteraction
