import React, { useEffect, useState } from 'react'
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import {
  NavigationBar,
  NavigationMenuMobile,
} from '../../components/navigation'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import PlaneIcon from './components/planeIcon/PlaneIcon'

// import { NavigationMenuMobile } from '../../components/navigation'
import { MdClose } from 'react-icons/md'

import './styles/createPostStylesMobile.css'
import './styles/createPostStylesTablet.css'
import './styles/createPostStylesDesktop.css'

import CreatePostTextArea from './components/CreatePostTextArea/CreatePostTextArea'
import { camera, cameraInner } from '../../assets'

const CreatePost = ({ parent }) => {
  // const user = {
  //   id: '123456789',
  //   username: 'Jeet Patel',
  //   profileImg:
  //     'https://docs.microsoft.com/en-us/answers/storage/attachments/209536-360-f-364211147-1qglvxv1tcq0ohz3fawufrtonzz8nq3e.jpg',
  // }

  const user = useSelector((state) => state.user.user)

  const image = {
    url: 'https://i.imgur.com/RywCM3i.jpeg',
    alt: 'user image',
  }

  // framework for making this more generalized, will take a parent comment or post and reply to it
  // if there isn't a parent, it will be treated as a Create New Post
  // if there is a parent, it will be treated as a Reply to Post/Comment
  // parent = {
  // parentPostId: 123456789,
  // parentUserId: 987654321,
  //   parentOwnerImg: 'https://i.imgur.com/RywCM3i.jpeg',
  //   body: "blah blah blah blah text"
  // }

  const [postText, setPostText] = useState('')
  const [postImages, setPostImages] = useState([])
  const [imageSelectModalToggle, setImageSelectModalToggle] = useState(false)

  const handleUpdateText = (e) => {
    setPostText(e.target.value)
  }

  const onSubmitPost = () => {
    if (postText.length > 0 || postImages.length > 0) {
      const postData = {
        user: user._id,
        text: postText,
        images: postImages,
      }

      // will replace log with call to backend
      console.log(postData)
      setPostText('')
      setPostImages([])
    }
  }

  const onOpenImageModal = () => {
    setImageSelectModalToggle((prev) => !prev)
  }

  // these lines of code set up an event listener on the entire page that closes the modal when it's opened
  // the event listener then deletes itself
  useEffect(() => {
    if (imageSelectModalToggle) {
      document.addEventListener('click', onCloseImageModal)
    }
  }, [imageSelectModalToggle])

  const onCloseImageModal = () => {
    setImageSelectModalToggle(false)
    document.removeEventListener('click', onCloseImageModal)
  }

  const onTakePhoto = (e) => {
    e.stopPropagation()
    setPostImages((prev) => [...prev, image])
  }

  const onTakeVideo = (e) => {
    e.stopPropagation()
    setPostImages((prev) => [...prev, image])
  }

  const onSelectPhoto = (e) => {
    e.stopPropagation()
    setPostImages((prev) => [...prev, image])
  }

  const handleRemoveImageUpload = (i) => {
    // making copy of array to not alter existing state
    const removedIndex = postImages.filter((val, index) => index !== i)
    setPostImages(removedIndex)
  }

  return (
    <>
      <div className="CreatePostPageWrapper">
        <NavigationBar></NavigationBar>
        <div className="CreatePostGrayBar"></div>
        <div className="CreatePostContainer">
          <div className="CreatePostBody">
            <div className="CreatePostHeader">
              <h1 className="CreatePostTitle">
                {parent ? 'Create Reply' : 'Create Post'}
              </h1>
              <Link to={'/feed'} className="CreatePostBackButton">
                <MdClose className="CreatePostXIcon" />
              </Link>
            </div>

            <div>{parent ? parent.body : ''}</div>
            <div className="CreatePostDetails">
              <div className="CreatePostProfileImgWrapper">
                {user.profileImg && (
                  <img
                    className="CreatePostProfileImg"
                    src={user.profileImg}
                  ></img>
                )}
              </div>
              <div className="CreatePostUserDetails">
                <p className="CreatePostUsername">
                  {user.firstName} {user.lastName}
                </p>
                <p className="CreatePostTime">
                  {new Date().toLocaleString() + ''}
                </p>
              </div>
            </div>

            <div className="CreatePostTextComponentWrapper">
              <CreatePostTextArea
                postText={postText}
                postImages={postImages}
                handleUpdateText={handleUpdateText}
                handleRemoveImageUpload={handleRemoveImageUpload}
              />
            </div>

            <div className="CreatePostButtons">
              <div
                className="CreatePostCameraWrapper"
                onClick={onOpenImageModal}
              >
                <img src={camera} className="CreatePostCameraButton" />
                <img src={cameraInner} className="CreatePostCameraInner" />
              </div>
              <div
                className={
                  imageSelectModalToggle
                    ? 'CreatePostImageModalWrapper-Visible'
                    : 'CreatePostImageModalWrapper-Hidden'
                }
              >
                <div className="CreatePostImageModal">
                  <div
                    className="CreatePostImageSelectButton"
                    onClick={onTakePhoto}
                  >
                    <PlaneIcon />
                    <p className="CreatePostImageSelectText">Take a photo</p>
                    {/* <input type="file" className='CreatePostImageSelectText' /><p>uplad</p> */}
                  </div>
                  <div
                    className="CreatePostImageSelectButton"
                    onClick={onTakeVideo}
                  >
                    <PlaneIcon />
                    <p className="CreatePostImageSelectText">Take a video</p>
                  </div>
                  <div
                    className="CreatePostImageSelectButton"
                    onClick={onSelectPhoto}
                  >
                    <PlaneIcon />
                    <p className="CreatePostImageSelectText">Select a photo</p>
                  </div>
                </div>

                {/* empty square that is angled off of the modal */}
                <div className="CreatePostImageModalTextBubble"></div>
              </div>
              <button
                className={
                  postText.length > 0
                    ? 'CreatePostSubmitButton-Active'
                    : 'CreatePostSubmitButton-Inactive'
                }
                onClick={onSubmitPost}
              >
                {parent ? 'Reply' : 'Post'}
              </button>
            </div>
          </div>
        </div>

        <div style={{ position: 'fixed', bottom: '0', left: '0', right: '0' }}>
          <NavigationMenuMobile></NavigationMenuMobile>
        </div>
      </div>
    </>
  )
}

CreatePost.propTypes = {
  parent: {
    body: PropTypes.string,
  },
}

export default CreatePost
