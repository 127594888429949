import React from 'react'
import { Ind, Org, Np } from '../../../../assets/index'
import './style.css'

const list = [
  {
    src: Ind,
    alt: 'Individual',
    description:
      'Get matched with the causes that you are passionate about and share your actual impact with an engaging online social community!',
    key: 1,
  },
  {
    src: Np,
    alt: 'Nonprofit',
    description:
      'Automatically connect with local volunteers that match your mission and events. Easily increase your revenue, volunteer pool, and visibility!',
    key: 2,
  },
  {
    src: Org,
    alt: 'Organization',
    description:
      'Manage your organization and highlight your unique philanthropic mission. Use our digital platform to build equity and expand your network!',
    key: 3,
  },
]
function Wwd() {
  return (
    <div className="container sectionWwd" id="WhatWeDo">
      <h4 className="text-center shead">A Powerful Platform For All</h4>
      <h2 className="text-center bhead">We Are The Future Of Philanthropy</h2>
      <div className="row concen">
        {list.map((card) => (
          <div className="col-12 col-lg-6 col-xl-4" key={card.key}>
            <img src={card.src} alt={card.alt} className="auto" />
            <div className="card shadow">
              <p className="mt-2 tri">{card.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Wwd
