import React from 'react'
import propTypes from 'prop-types'

export const UserInformation = ({
  firstName = 'User',
  lastName = 'Mockup',
  datePosted,
}) => {
  const fullName = `${firstName} ${lastName}`
  return (
    <div className="userInformationComponentBlock">
      <div className="userInformationUsername">
        <p>{fullName}</p>
      </div>
      <div className="userInformationDatePosted">
        <p>{datePosted}</p>
      </div>
    </div>
  )
}

UserInformation.propTypes = {
  firstName: propTypes.string,
  lastName: propTypes.string,
  datePosted: propTypes.instanceOf(Date),
}
