import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Mail, errSign } from '../../../../assets'
import { EmailChecker } from '../../../../util/emailCheck'
import API from '../../../../util/dbAPI'
import './style.css'

function PopUpBox() {
  const [show, setShow] = useState(false)
  const [errState, setErr] = useState(false)
  const [state, setState] = useState({ email: '' })

  const { email } = state

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleChange = (e) => {
    const email = e.target.value
    setState({
      email: email,
    })
    setErr(false)
  }

  // check if email is valid before messing with submission and state.
  const handleSubmit = async (e) => {
    e.preventDefault()

    const isValid = await EmailChecker(email)
    if (isValid === false) {
      setErr(true)
      return
    }

    console.log('Inside handler')
    console.log('email: ', email)
    const jsonEmail = {
      email: email,
    }
    console.log('jsonEmail: ', jsonEmail)
    API.subscribeEmail(jsonEmail)
      // axios
      //   .post(
      //     'http://darbeapi-env.eba-8i8bhwmi.us-east-2.elasticbeanstalk.com/subscribeemailu',
      //     jsonEmail
      //   )

      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })

    handleShow()
    e.target[0].value = ''
    setState({
      email: '',
    })
  }

  return (
    <>
      <div className="CtaArea">
        <p className="text-light mb-2 Ctae">Get Free Monthly E-mail Updates</p>
        <form onSubmit={handleSubmit} style={{ padding: 0 }}>
          <input
            type="email"
            name="email"
            onChange={handleChange}
            placeholder="Enter your email here"
            className={errState === true ? 'cta2-email error' : 'cta2-email'}
            autoComplete="off"
          />
          {errState === true ? (
            <img src={errSign} className="err-sign" alt="error" />
          ) : (
            <> </>
          )}
          <button
            id="email"
            className={`text-uppercase submit-button ${
              errState === true && 'submit-button-err'
            } text-light`}
          >
            Submit
          </button>
        </form>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <img src={Mail} alt="Mail Icon" />
        </Modal.Header>
        <Modal.Body>
          <p className="thank-you-msg">Thank You!</p>
          <p className="modal-text">
            Your email was successfully submitted. Check your email for
            Darbe&apos;s welcome letter!
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default PopUpBox
