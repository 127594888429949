import React from 'react'
import PropTypes from 'prop-types'

export default function MessageRender({ messageLog, name }) {
  function timeStampRender(timeStamp) {
    const tSC = new Date(timeStamp)
    const [hour, minutes] = [tSC.getHours(), tSC.getMinutes()]
    // i know this return looks confusing but its using inline if else statements to make this whole function clean
    return `${hour > 12 ? hour - 12 : hour}:${
      (minutes < 10 ? '0' : '') + minutes
    }${hour > 12 ? 'pm' : 'am'}`
  }

  return messageLog?.map((message, i) => (
    <div key={i} className={message.name === name ? 'you' : 'other'}>
      {message.name === name ? (
        <div className="messageBubble">
          <p>{message.body}</p>
        </div>
      ) : (
        <div className="otherFlex">
          <img src="https://via.placeholder.com/50" className="mProfile" />
          <div className="messageBubble">
            <p>{message.body}</p>
          </div>
        </div>
      )}
      <p className="mTimeStamp">{timeStampRender(message.timestamp)}</p>
    </div>
  ))
}

MessageRender.propTypes = {
  messageLog: PropTypes.array,
  name: PropTypes.string,
}
