import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import CausesRowOne from './CausesRowOne'
import CausesRowTwo from './CausesRowTwo'
import CausesRowThree from './CausesRowThree'
import CausesRowFour from './CausesRowFour'
import CausesRowFive from './CausesRowFive'

const CausesRender = ({ causes, typeUser, grabData }) => {
  const [userCauses, setUserCauses] = useState([])
  // Split into subarrays for easier styling
  // we use array subsets instead and display each as its own mini flex row
  const rowOne = causes.slice(0, 4)
  const rowTwo = causes.slice(4, 8)
  const rowThree = causes.slice(8, 13)
  const rowFour = causes.slice(13, 17)
  const rowFive = causes.slice(17, 22)

  useEffect(() => {
    console.log(userCauses)
    if (userCauses.length > 2 && typeUser === 'Entity') {
      if (userCauses.includes()) {
        setUserCauses(userCauses.slice(0, 2))
      }
    }
    grabData(userCauses)
  }, [userCauses])

  return (
    <div className="causeRender">
      <CausesRowOne
        causes={rowOne}
        userCauses={userCauses}
        setUserCauses={setUserCauses}
        grabData={grabData}
        entity={typeUser}
      />
      <CausesRowTwo
        causes={rowTwo}
        userCauses={userCauses}
        setUserCauses={setUserCauses}
        grabData={grabData}
        entity={typeUser}
      />
      <CausesRowThree
        causes={rowThree}
        userCauses={userCauses}
        setUserCauses={setUserCauses}
        grabData={grabData}
        entity={typeUser}
      />
      <CausesRowFour
        causes={rowFour}
        userCauses={userCauses}
        setUserCauses={setUserCauses}
        grabData={grabData}
        entity={typeUser}
      />
      <CausesRowFive
        causes={rowFive}
        userCauses={userCauses}
        setUserCauses={setUserCauses}
        grabData={grabData}
        entity={typeUser}
      />
    </div>
  )
}

CausesRender.propTypes = {
  causes: propTypes.array,
  typeUser: propTypes.string,
  grabData: propTypes.func,
}

export default CausesRender
