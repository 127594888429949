import React, { useState } from 'react'
import propTypes from 'prop-types'
import { entityStepThree } from '../../../../assets'

const EntitySignUp = ({
  stepUp,
  stepDown,
  dataModify,
  data,
  differentiate,
  setDataObj,
}) => {
  const [entityData, setEntityData] = useState({})

  const handleChange = (evt) => {
    const { name, value } = evt.target
    setEntityData({ ...entityData, [name]: value })
  }

  const populateUp = () => {
    setDataObj((data) => ({ ...data, ...entityData }))
  }

  const placeholder = `Your ${differentiate}`

  return (
    <div>
      <img className="stepThreesignUpForm entity" src={entityStepThree} />

      <div className="signupPageentityLocationForm">
        <div className="signupPagerow">
          <div className="signupPagecolumn signupPageEntityName">
            <label htmlFor="text">{differentiate} Name</label>
            <input
              type="text"
              name="entityName"
              placeholder={placeholder}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="signupPagerow">
          <div className="signupPagecolumn signupPagecity">
            <label htmlFor="city">City</label>
            <input
              type="text"
              name="city"
              placeholder="Your city"
              onChange={handleChange}
            />
          </div>
          <div className="signupPagecolumn signupPagezipcode">
            <label htmlFor="zipcode">Zipcode</label>
            <input
              type="text"
              name="zipcode"
              placeholder="Your zipcode"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="entitySignupMoreInfo signupPagepaginationButtons">
        <div className="signupPagebuttonLeftColumn">
          <button className="signupPagepreviousButton" onClick={stepDown}>
            Previous
          </button>
        </div>
        <div className="signupPagebuttonRightColumn" onClick={populateUp}>
          <button className="signupPagenextButton" onClick={stepUp}>
            Next
          </button>
        </div>
      </div>
    </div>
  )
}

EntitySignUp.propTypes = {
  stepUp: propTypes.func,
  stepDown: propTypes.func,
  dataModify: propTypes.func,
  data: propTypes.func,
  differentiate: propTypes.string,
  setDataObj: propTypes.func,
}

export default EntitySignUp
