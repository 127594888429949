import React, { useState } from 'react'
import propTypes from 'prop-types'
import { stepFive, closeDropDown, openDropDown } from '../../../../assets'
import { DropdownList } from '../helpers/DropdownList'

const Availability = ({ stepUp, stepDown, dataModify, data, setDataObj }) => {
  const [entityData, setEntityData] = useState({})
  const dayList = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]
  const [dropDownIconShow, setdropDownIconShow] = useState(true)
  const [toggleHours, setToggleHours] = useState(false)
  const [hourValue, setHourValue] = useState(new Map())
  const [componentStack, setComponentStack] = useState([])
  const handleChange = (evt) => {
    const { name, value } = evt.target
    setEntityData({ ...entityData, [name]: value })
  }
  const hour = 'hour'

  const toggleDropDownMenu = (evt) => {
    const { id } = evt.target
    setToggleHours((toggleHours) => !toggleHours)
    setComponentStack((components) => [...components, id])
    changeIcons(id)
  }

  const resetToggles = (name) => {
    if (toggleHours) {
      setToggleHours((toggleHours) => !toggleHours)
      setComponentStack((components) => {
        return [...components.filter((component) => component !== name)]
      })
      changeIcons(name)
      updateValueOfInput(name)
    }
  }

  const changeIcons = (name) => {
    if (checkComponentStack(name)) {
      setComponentStack((components) => {
        return [...components.filter((component) => component !== name)]
      })
    }
    const imageElement = document.getElementById(name)
    if (dropDownIconShow) {
      imageElement.src = closeDropDown
    }
    if (!dropDownIconShow) {
      imageElement.src = openDropDown
    }

    setdropDownIconShow((dropDownIconShow) => !dropDownIconShow)
  }

  const checkComponentStack = (key) => {
    return componentStack.includes(key)
  }

  const updateValueOfInput = (id) => {
    const inputToUpdate = document.getElementById(id)
    inputToUpdate.value = hourValue.get(id)
  }

  const displayAvailability = dayList.map((day, idx) => {
    const classDisplay = `${day}Div`
    const timeDisplayName = `${day}Times`
    const am = `am${day}`
    const pm = `pm${day}`

    return (
      <div key={idx} className={classDisplay}>
        <div className={day}>
          <input type="checkbox" name={day} onClick={handleChange} />
          <label htmlFor={day}>{day}</label>
        </div>
        <div className={timeDisplayName}>
          <input list="hour-list" name="hours" id={am} placeholder="12:00am" />
          <img
            className="amdropDownArrowPlacement dropDownArrowPlacement"
            name="am"
            id={am}
            src={openDropDown}
            onClick={toggleDropDownMenu}
          />
          {checkComponentStack(am) ? (
            <DropdownList
              typeDisplay={hour}
              resetFunc={resetToggles}
              setValue={setHourValue}
              availabilityHelper={am}
            />
          ) : (
            ''
          )}
          <label htmlFor="timeConnector">To</label>
          <input list="hour-list" name="hours" id={pm} placeholder="12:00am" />
          <img
            className="pmdropDownArrowPlacement dropDownArrowPlacement"
            name="pm"
            id={pm}
            src={openDropDown}
            onClick={toggleDropDownMenu}
          />
          {checkComponentStack(pm) ? (
            <DropdownList
              typeDisplay={hour}
              resetFunc={resetToggles}
              setValue={setHourValue}
              availabilityHelper={pm}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    )
  })

  const iterateMap = () => {
    const returnableTimeObject = {}

    for (const key in entityData) {
      const amKey = `am${key}`
      const pmKey = `pm${key}`

      if (hourValue.has(amKey) && hourValue.has(pmKey)) {
        returnableTimeObject[key] = {
          amKey: hourValue.get(amKey),
          pmKey: hourValue.get(pmKey),
        }
      }
    }

    return returnableTimeObject
  }

  const populateUp = () => {
    const times = iterateMap()
    setDataObj((data) => ({ ...data, availability: [times] }))
  }

  return (
    <div className="signupPageavailabilityFormInput">
      <img className="stepFivesignUpForm" src={stepFive} />

      <div className="signupPageavailabilityDisplay">{displayAvailability}</div>

      <div className="signupPagepaginationButtons">
        <div className="signupPagebuttonLeftColumn">
          <button
            type="submit"
            className="signupPagepreviousButton"
            onClick={stepDown}
          >
            Previous
          </button>
        </div>
        <div className="signupPagebuttonRightColumn" onClick={populateUp}>
          <button
            type="submit"
            className="signupPagenextButton"
            onClick={stepUp}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )
}

Availability.propTypes = {
  stepUp: propTypes.func,
  stepDown: propTypes.func,
  dataModify: propTypes.func,
  data: propTypes.func,
  setDataObj: propTypes.func,
}

export default Availability
