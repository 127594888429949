import React from 'react'
import { NavigationBar } from '../../components/navigation'

import './style.css'

export const TestingPage = () => {
  return (
    <>
      <NavigationBar />
      <h1 className="text-center">Use this page to test new components</h1>
      <div
        style={{
          display: 'flex',
          // justifyContent: 'center',
          alignItems: 'center',
          minHeight: window.innerHeight * 0.5,
          borderWidth: 3,
          borderStyle: 'dashed',
          borderColor: 'pink',
        }}
      ></div>
    </>
  )
}
