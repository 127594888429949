import React from 'react'
import PropTypes from 'prop-types'
import './index.css'

function ChatInput({ chatBox, updateChatBox, sendMessage }) {
  // for timestamps being made.
  const timeStamp = new Date().toISOString().toString()

  return (
    <div className="formBackDrop">
      <form
        className="form-pad"
        onSubmit={(e) => {
          e.preventDefault()
          updateChatBox({
            name: chatBox.name,
            conversation: chatBox.conversation,
            body: e.target.value,
            timestamp: timeStamp,
          })
          sendMessage()
        }}
      >
        <input
          type="text"
          className="messageInput"
          value={chatBox.body}
          onChange={(e) =>
            updateChatBox({
              name: chatBox.name,
              conversation: chatBox.conversation,
              body: e.target.value,
              timestamp: timeStamp,
            })
          }
        />
        <button className="messageBtn">Send</button>
      </form>
    </div>
  )
}

ChatInput.propTypes = {
  chatBox: PropTypes.object,
  updateChatBox: PropTypes.func,
  sendMessage: PropTypes.func,
}

export default ChatInput
