import React from 'react'
import './style.css'
import Jumbotron from 'react-bootstrap/Jumbotron'
import PopUpBox from '../PopUpBox'
// import EmailCta from "../EmailInput";

function Hero() {
  return (
    <Jumbotron className="" id="Home">
      <div className="text-container">
        <p className="headline">
          The <b>One-Stop-Shop</b> For All Of Your Philanthropy Needs
        </p>
        <p className="subheading">
          We help volunteers, nonprofits, and organizations come together on one
          social networking platform. Our platform will be here in November, so
          enter your email to stay fully updated on Darbe!
        </p>
        <PopUpBox />
      </div>
    </Jumbotron>
  )
}

export default Hero
