import {
  appleIcon,
  googleIcon,
  fancyLine,
  passwordHideIcon,
  passwordShowIcon,
  stepTwo,
} from '../../../../assets'
import React, { useState } from 'react'
import propTypes from 'prop-types'
import dbAPI from '../../../../util/dbAPI'

const UserInfo = ({ stepUp, stepDown, dataModify, data, setDataObj }) => {
  const [entityData, setEntityData] = useState({})
  const [passwordShow, setPasswordShow] = useState(true)
  const [passwordShowTwo, setPasswordShowTwo] = useState(true)

  const handleChange = (evt) => {
    const { name, value } = evt.target
    setEntityData({ ...entityData, [name]: value })
  }

  const populateUp = () => {
    setDataObj((data) => ({ ...data, ...entityData }))
  }

  const changeIcons = () => {
    //  for hide and show password
    const divInput = document.querySelector('.signupPagepasswordInput input')
    const imageElement = document.querySelector('#userPassword')

    if (passwordShow) {
      divInput.setAttribute('type', 'text')
      imageElement.src = passwordShowIcon
    }
    if (!passwordShow) {
      divInput.setAttribute('type', 'password')
      imageElement.src = passwordHideIcon
    }

    setPasswordShow((passwordShow) => !passwordShow)
  }

  const changeIconsTwo = () => {
    //  for hide and show password
    const divInput = document.querySelector('.confirmPassword input')
    const imageElement = document.querySelector('#userInfoPasswordConfirm')

    if (passwordShowTwo) {
      divInput.setAttribute('type', 'text')
      imageElement.src = passwordShowIcon
    }
    if (!passwordShowTwo) {
      divInput.setAttribute('type', 'password')
      imageElement.src = passwordHideIcon
    }

    setPasswordShowTwo((passwordShowTwo) => !passwordShowTwo)
  }

  const OAuthStart = (evt) => {
    evt.preventDefault()

    evt.target.id === 'google' ? dbAPI.googleOAuth() : dbAPI.appleOAuth()
  }

  return (
    <div>
      <img className="stepTwosignUpFormUser" src={stepTwo} />
      <div className="signupPageuserInfoForm usersInfoFormSubClass">
        <div className="verticalLayoutSignup userInfoSignUpInputs">
          <div className="signupPageemailInput signupPageuserForm">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              placeholder="user@email.com"
              onChange={handleChange}
              required
            />
          </div>

          <div className="signupPagepasswordInput signupPageuserForm">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="userPassword"
              name="passwordOne"
              placeholder="● ● ● ● ● ● ● ● ●"
              onChange={handleChange}
              required
            />
            <img
              onClick={changeIcons}
              id="passwordIcon"
              className="loginPagepasswordIcon"
              src={passwordHideIcon}
              required
            />
          </div>

          <div className="signupPagepasswordInput signupPageuserForm confirmPassword">
            <label htmlFor="password">Re-enter Password</label>
            <input
              type="password"
              id="userInfoPasswordConfirm"
              name="passwordTwo"
              placeholder="● ● ● ● ● ● ● ● ●"
              onChange={handleChange}
            />
            <img
              onClick={changeIconsTwo}
              id="passwordIcon"
              className="loginPagepasswordIcon"
              src={passwordHideIcon}
            />
          </div>
        </div>

        <div className="signupPagelines">
          <div>
            <img src={fancyLine} className="fancyLines"></img>
          </div>
          <div>
            <p className="wordBetweenLines"> or </p>
          </div>
          <div>
            <img src={fancyLine} className="fancyLines"></img>
          </div>
        </div>

        <div className="signupPageOAUthButtonVertical">
          <div className="signupPageOAuthButtons">
            <img src={googleIcon} className="googleIcon"></img>
            <button
              className="googleOAuthButton"
              id="google"
              onClick={OAuthStart}
            >
              {' '}
              Log in with Google
            </button>

            <img src={appleIcon} className="appleIcon"></img>
            <button
              className="appleOAuthButton"
              id="apple"
              onClick={OAuthStart}
            >
              {' '}
              Log in with Apple
            </button>
          </div>
        </div>
      </div>
      <div className="signupPagepaginationButtons userSignupInfo usersInfoFormSubClass">
        <div className="signupPagebuttonLeftColumn">
          <button
            type="submit"
            className="signupPagepreviousButton"
            onClick={stepDown}
          >
            Previous
          </button>
        </div>
        <div className="signupPagebuttonRightColumn" onClick={populateUp}>
          <button
            type="submit"
            className="signupPagenextButton"
            onClick={stepUp}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}

UserInfo.propTypes = {
  stepUp: propTypes.func,
  stepDown: propTypes.func,
  dataModify: propTypes.func,
  data: propTypes.func,
  setDataObj: propTypes.func,
}

export default UserInfo
