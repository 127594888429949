import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'

export const DropdownList = ({
  typeDisplay,
  resetFunc,
  setValue,
  availabilityHelper = '',
}) => {
  const [displayDays, setDisplayDays] = useState([])
  const [displayYears, setDisplayYears] = useState([])
  const [displayMonths, setDisplayMonths] = useState([])
  const [displayHours, setDisplayHours] = useState([])
  const [dataPicked, setDataPicked] = useState({})
  const days = []
  const years = []
  const listOfMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  let display
  const fillData = (evt) => {
    const { value } = evt.target
    setDataPicked({ ...dataPicked, ...value })
  }

  const whatToDisplay = (typeContent) => {
    if (typeContent === 'day') {
      display = displayDays
    }
    if (typeContent === 'month') {
      display = displayMonths
    }
    if (typeContent === 'year') {
      display = displayYears
    }
    if (typeContent === 'hour') {
      display = displayHours
    }
  }

  const handlesClicks = (evt) => {
    const { id, innerText } = evt.target
    fillData(evt)
    if (availabilityHelper?.length) {
      setValue((map) => new Map(map.set(availabilityHelper, innerText)))
      resetFunc(availabilityHelper)
    } else {
      setValue(innerText)
      resetFunc(id)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      const currentYear = parseInt(new Date().getFullYear())
      for (let i = 1; i < 32; i++) {
        days.push(i)
      }

      for (let i = 1900; i < currentYear; i++) {
        years.push(i)
      }

      const hours = []

      for (let i = 0; i < 2450; i += 50) {
        let hourCombo = ''
        let tracker = i
        let ampm = 'am'
        if (tracker > 1250) {
          tracker -= 1200
          ampm = 'pm'
        }
        if (tracker % 100 === 0) {
          hourCombo += (tracker / 100).toString() + ':' + '00 ' + ampm
        }
        if (tracker % 100 !== 0) {
          hourCombo += ((tracker - 50) / 100).toString() + ':' + '30 ' + ampm
        }
        hours.push(hourCombo)
      }

      setDisplayHours([
        hours.map((hour, idx) => {
          return (
            <div
              key={idx}
              id={availabilityHelper}
              name={'hour'}
              onClick={handlesClicks}
            >
              <p id={'hour'}>{hour}</p>
            </div>
          )
        }),
      ])

      setDisplayDays((displayDays) => [
        ...displayDays,
        days.map((day, key) => {
          return (
            <div key={key} id={day} name={day} onClick={handlesClicks}>
              <p id={typeDisplay}>{day}</p>
            </div>
          )
        }),
      ])
      setDisplayYears((displayYears) => [
        ...displayYears,
        years.map((year, key) => {
          return (
            <div key={key} id={year} name={year} onClick={handlesClicks}>
              <p id={typeDisplay}>{year}</p>
            </div>
          )
        }),
      ])
      setDisplayMonths((displayMonths) => [
        ...displayMonths,
        listOfMonths.map((month, key) => {
          return (
            <div key={key} value={month} name={month} onClick={handlesClicks}>
              <p id={typeDisplay}>{month}</p>
            </div>
          )
        }),
      ])
    }, 200)
  }, [])

  whatToDisplay(typeDisplay)
  const divClassName = `dropDownContent ${availabilityHelper?.slice(0, 2)}`
  return <div className={divClassName}>{display}</div>
}

DropdownList.propTypes = {
  typeDisplay: propTypes.string,
  resetFunc: propTypes.func,
  setValue: propTypes.func,
  availabilityHelper: propTypes.string,
}
