import * as React from 'react'

const SvgMatch = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 29 29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.958 16.959v11.666h11.667V16.96H14.958ZM.375 28.625h11.667V16.96H.375v11.666Zm0-26.25v11.667h11.667V2.375H.375Zm19.92-1.91-8.253 8.24 8.254 8.254 8.254-8.255-8.254-8.24Z"
    />
  </svg>
)

export default SvgMatch
