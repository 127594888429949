import * as React from 'react'

const SvgCircleIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy={12} r={11.5} />
  </svg>
)

export default SvgCircleIcon
