import * as React from 'react'

const SvgImpact = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 31"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.292 14.041H.46v16.042h5.833V14.04ZM22.334 2.739c.948-1.108 2.42-1.823 3.938-1.823 2.697 0 4.812 2.115 4.812 4.813 0 3.31-4.244 7.145-8.75 11.229-4.506-4.098-8.75-7.934-8.75-11.23 0-2.697 2.115-4.812 4.813-4.812 1.516 0 2.99.715 3.937 1.823ZM28.167 22.791H17.96l-3.048-1.065.481-1.37 2.567.977h4.113c.947 0 1.72-.773 1.72-1.721 0-.715-.452-1.356-1.123-1.619l-10.587-3.952H9.209v13.154l10.208 2.888L31.1 25.708a2.938 2.938 0 0 0-2.932-2.917Z"
    />
  </svg>
)

export default SvgImpact
