import React from 'react'
import PopUpBox from '../PopUpBox'
import './style.css'

function CallToAction2() {
  return (
    <div className="Cta2-hero p-xl-3">
      <div className="row cta2">
        <div className="col-xl-6 col-sm-12 cta2-mobile">
          <h3 className="text-light cta2-h3">Philanthropy Can Be Complex.</h3>
          <h2 className="text-light cta2-h2">Let Us Do The Work For You.</h2>
        </div>
        <div className="col-xl-4 offset-xl-1 col-12 cta2-form">
          <PopUpBox />
        </div>
      </div>
    </div>
  )
}

export default CallToAction2
