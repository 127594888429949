import React from 'react'
import PropTypes from 'prop-types'
import { CircleIcon } from '../IconComponents/index'
import './iconButton.css'

const IconButton = ({
  text,
  handleClick,
  icon,
  active = false,
  type = 'UNKNOWN',
}) => {
  return (
    <div className="icon-button" onClick={handleClick}>
      <div
        className={`icon-button-icon-wrapper ${
          active ? 'icon-button-active' : ''
        }`}
      >
        <div className="icon-button-circle">
          <CircleIcon />
        </div>
        <div className="icon-button-icon">{icon}</div>
      </div>
      <div className="icon-button-text">{text}</div>
    </div>
  )
}

// Prop types
IconButton.propTypes = {
  text: PropTypes.string.isRequired,
  handleClick: PropTypes.func.required,
  icon: PropTypes.element.isRequired,
  type: PropTypes.string,
  active: PropTypes.bool,
}

export default IconButton
