import React from 'react'
import { Logo } from '../../../assets'
import Render from './Render'
import '../styles/style.css'

const SignUp = () => {
  return (
    <div className="signupPage">
      <div className="signupPagenavBar">
        <div className="signupPagenavBarContents">
          <div className="signupPagelogo">
            <img className="signupPagenavbarLogo" src={Logo} />
          </div>
        </div>
        <div className="signupPagenavBarContets">
          <div className="signupPagesignUpArea">
            <p className="signupPageactionText">Already Have An Account?</p>
            <button className="signupPageloginButton">Log In</button>
          </div>
        </div>
      </div>

      <div className="signupPageformContainer">
        <div className="signupPagesignupModal">
          <div className="signupPagetitleSignup">
            <p>Sign Up</p>
          </div>

          <div>
            <Render />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUp
