import React from 'react'
import { DarbeLogo } from '../../../assets/index'
import propTypes from 'prop-types'

export const ProfilePicture = ({
  profilePicUrl = DarbeLogo,
  classValue = 'profilePicture',
}) => {
  return (
    <div className={classValue}>
      <img src={profilePicUrl}></img>
    </div>
  )
}

ProfilePicture.propTypes = {
  profilePicUrl: propTypes.string,
  classValue: propTypes.string,
}
