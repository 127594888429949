import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'

export const ContentType = ({
  identifier,
  contentType,
  textContent = '',
  photoURL = [],
}) => {
  const [cssClassApplied, setCssClassApplied] = useState('')
  const [populatedImages, setPopulatedImages] = useState([])
  const [displayText, setDisplayText] = useState('')
  const [displayCarousel, setDisplayCarousel] = useState([''])
  const slideShow = []
  const textBoxContentClassName = `${identifier} textBoxContent constrictedTextBox`

  const stepFunction = (evt) => {
    if (photoURL.length > 1) {
      const { id } = evt.target
      const numberExtractor = /\d+/g
      const extractedNumber = id.match(numberExtractor)[1]
      const whichImageToFlip = `${identifier}feedCardPhotoDots`
      document.querySelector(`#${whichImageToFlip}`).src =
        slideShow[parseInt(extractedNumber)]
      document.querySelector(
        `.${identifier}.feedCardPhotoDotsActiveSelection`
      ).classList = ''
      document.querySelector(
        `#${id}`
      ).classList = `${identifier} feedCardPhotoDotsActiveSelection`
    }
  }

  const textBoxSizeChange = (evt) => {
    const seeMoreSpan = document.querySelector(`.seeMoreSpan`)
    seeMoreSpan.classList = 'hideSeeMoreSpan'
    document.querySelector(
      `.${identifier}.textBoxContent.constrictedTextBox`
    ).classList = ''
    const newTextElement = <p>{textContent}</p>
    setDisplayText(newTextElement)
  }

  useEffect(() => {
    switch (contentType) {
      case 1:
        setCssClassApplied('feedCardOnlyText')
        break
      case 2:
        setCssClassApplied('feedCardOnlyPhoto')
        break
      case 3:
        setCssClassApplied('feedCardMultiplePhotos')
        break
      case 4:
        setCssClassApplied('feedCardTextWithPhoto')
        break
      case 5:
        setCssClassApplied('feedCardTextWithMultiplePhotos')
        break
      default:
        setCssClassApplied('feedCardErrorLoadingContent')
        break
    }

    if (photoURL.length) {
      if (photoURL.length < 2) {
        for (const url of photoURL) {
          const newImageElement = <img src={url} />
          setPopulatedImages((populatedImages) => [
            ...populatedImages,
            newImageElement,
          ])
        }
      } else {
        const dots = []
        for (let i = 0; i < photoURL.length; i++) {
          slideShow.push(photoURL[i])
          const dotIdentification = `${identifier}dot${i}`
          const uniqueClassName = `${identifier} feedCardPhotoDotsActiveSelection`
          if (i === 0) {
            const specialDot = (
              <div
                className={uniqueClassName}
                key={dots.length}
                onClick={stepFunction}
                id={dotIdentification}
              ></div>
            )
            dots.push(specialDot)
          } else {
            const dot = (
              <div
                key={dots.length}
                onClick={stepFunction}
                id={dotIdentification}
              ></div>
            )
            dots.push(dot)
          }
        }
        const userImageIdentifer = `${identifier}feedCardPhotoDots`
        const fullElement = (
          <>
            <img id={userImageIdentifer} src={photoURL[0]} />
            <div className="feedCardPhotoDots">{dots}</div>
          </>
        )
        setDisplayCarousel(fullElement)
      }
    }

    if (textContent.length > 0 && textContent.length < 80) {
      const textElement = <p>{textContent}</p>
      setDisplayText(textElement)
    }

    if (textContent.length > 0 && textContent.length > 80) {
      const spanClick = (
        <span className="seeMoreSpan" onClick={textBoxSizeChange}>
          ...see more
        </span>
      )
      const textElement = (
        <p>
          {textContent.split(' ').slice(0, 10).join(' ') + ' ' + ' '}
          {spanClick}
        </p>
      )
      setDisplayText(textElement)
    }
  }, [])

  return (
    <div className={cssClassApplied}>
      <>{textContent.length < 80 ? <div>{displayText}</div> : ''}</>
      <>
        {textContent.length > 80 ? (
          <div className={textBoxContentClassName}>{displayText}</div>
        ) : (
          ''
        )}
      </>
      <>
        {photoURL.length > 0 && photoURL.length < 2 ? (
          <div>{populatedImages}</div>
        ) : (
          ''
        )}
      </>
      <>
        {photoURL.length > 1 ? (
          <div className="feedCardpictureHolder">{displayCarousel}</div>
        ) : (
          ''
        )}
      </>
    </div>
  )
}

ContentType.propTypes = {
  identifier: propTypes.string,
  contentType: propTypes.number,
  textContent: propTypes.string,
  photoURL: propTypes.array,
}
