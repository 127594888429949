import React, { useState } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Logo } from '../../../../assets'
import './style.css'

function Header() {
  const [expanded, setExpanded] = useState(false)

  function scrollPage(width, section) {
    // getting the width of the screen and setting the range variable to a property of sectionLibrary object
    let range
    if (width < 375) {
      range = 'verylow'
    } else if (width > 374 && width < 425) {
      range = 'low'
    } else if (width > 424 && width < 767) {
      range = 'lowmid'
    } else if (width > 766 && width < 991) {
      range = 'highmid'
    } else {
      range = 'high'
    }

    // contains the y-coordinate of each section based on the screens-width
    const sectionLibrary = {
      verylow: {
        wwd: 830,
        faq: 3150,
        founders: 7030,
        contactus: 7400,
      },
      low: {
        wwd: 830,
        faq: 2800,
        founders: 6570,
        contactus: 7000,
      },
      lowmid: {
        wwd: 825,
        faq: 3000,
        founders: 5870,
        contactus: 6500,
      },
      highmid: {
        wwd: 950,
        faq: 3500,
        founders: 6800,
        contactus: 90000,
      },
      high: {
        wwd: 700,
        faq: 1900,
        founders: 4400,
        contactus: 5000,
      },
    }
    setExpanded(false)
    return sectionLibrary[range][section]
  }

  return (
    <Navbar expand="lg" sticky="top" expanded={expanded}>
      <Navbar.Brand href="/">
        <img src={Logo} alt="Darbe Logo" />
      </Navbar.Brand>
      <Navbar.Toggle
        onClick={() => setExpanded(expanded ? false : 'expanded')}
        aria-controls="responsive-navbar-nav"
      />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav className="me-auto">
          <Nav.Link
            onClick={() =>
              window.scrollTo(0, scrollPage(window.innerWidth, 'wwd'))
            }
          >
            What We Do
          </Nav.Link>

          <Nav.Link
            onClick={() =>
              window.scrollTo(0, scrollPage(window.innerWidth, 'faq'))
            }
          >
            FAQ
          </Nav.Link>

          <Nav.Link
            onClick={() =>
              window.scrollTo(0, scrollPage(window.innerWidth, 'founders'))
            }
          >
            Founder&apos;s Message
          </Nav.Link>

          <Nav.Link
            onClick={() =>
              window.scrollTo(0, scrollPage(window.innerWidth, 'contactus'))
            }
            id="no-bottom"
          >
            Contact Us
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Header
