import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { ProfilePicture } from '../../pages/feed/User/ProfilePicture'
import getState from '../../util/zipcode'

export const SearchResults = ({
  searchResults,
  searchTerm = '',
  fullPage = false,
}) => {
  const [listOfUsers, setListUsers] = useState([])
  const showLimit = searchResults?.length >= 5 && !fullPage

  useEffect(() => {
    const makeListUsers = (list) => {
      return list.map((l) => {
        return (
          <div key={l._id} className="matchingUser">
            <ProfilePicture classValue="searchBarProfilePictureResults" />
            <div className="matchingUserInformation">
              <span className="matchingUserFullName">{l.fullName}</span>
              <span className="matchingUserLocation">{`${l.city}, ${
                getState(l.zipcode).state
              }`}</span>
            </div>
          </div>
        )
      })
    }

    const results = makeListUsers(searchResults)
    setListUsers(results)
  }, [searchResults])

  return (
    <div className="searchResults">
      {listOfUsers}
      {showLimit ? (
        <a
          className="showMoreResults"
          href={`/searchResults?searchTerm=${searchTerm}`}
        >
          Show results for {searchTerm}
        </a>
      ) : (
        ''
      )}
    </div>
  )
}

export default SearchResults

SearchResults.propTypes = {
  searchResults: propTypes.array,
  searchTerm: propTypes.string,
  fullPage: propTypes.bool,
}
