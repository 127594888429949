import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import userRootReducer from './userReducer'
import thunk from 'redux-thunk'

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  userInfo: userRootReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

// any new reduces should be imported and added to the reducer object following same pattern
// configureStore replaces deprcated createStore
// we can modify the middleware, and add more. we can also add logging to this

// TODO add logging and update middleware when usecases are determined

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
})

const persistor = persistStore(store)

export { store, persistor }
