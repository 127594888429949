/* eslint-disable react/prop-types */
import './style/index.css'
import React from 'react'
import Header from './components/Header'
import CallToAction2 from './components/CTA2'
import FaQ from './components/faq'
import Footer from './components/footer'
import CeoMessage from './components/MFTCEO'
import Wwd from './components/WhatWeDo'
import Unsubscribe from './components/Unsubscribe'
import Hero from './components/Hero'

function LandingPage(props) {
  return (
    <>
      <Header />
      <Hero />
      <Wwd />
      <CallToAction2 />
      <FaQ />
      <CeoMessage />
      <Footer />

      {props.location.state !== undefined && props.location.state.unsub ? (
        <Unsubscribe email={props.location.state.unsubEmail} />
      ) : (
        <></>
      )}
    </>
  )
}

export default LandingPage
