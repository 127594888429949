import * as React from 'react'

const SvgHome = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 36 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.5 30V19.5h7V30h8.75V16h5.25L18 .25.5 16h5.25v14h8.75Z"
    />
  </svg>
)

export default SvgHome
