import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import {
  individualSignUp,
  nonprofitSignUp,
  organizationSignUp,
} from '../../../../assets'
import '../../styles/style.css'

const EntryForm = ({ stepUp, individualPath, orgPath, nonProfitPath }) => {
  const [focusList, setFocusList] = useState([])
  const focusedElements = ['Individual', 'Nonprofit', 'Organization']

  const deliverPath = () => {
    //  only send last focused element, as clicking a button is considered a select/focused action
    const activeElement = focusedElements.some(
      (element) => element === focusList[focusList.length - 1]
    )
    if (activeElement) {
      declarePath()
      stepUp()
    }
  }

  const declarePath = () => {
    if (!focusList.length) {
      return
    }

    if (focusList[focusList.length - 1] === 'Individual') {
      individualPath()
    }
    if (focusList[focusList.length - 1] === 'Nonprofit') {
      nonProfitPath()
    }
    if (focusList[focusList.length - 1] === 'Organization') {
      orgPath()
    }
  }

  useEffect(() => {
    document
      .querySelector('.signupPageindividualDiv')
      .addEventListener('click', (evt) => {
        setFocusList(['Individual'])
      })

    document
      .querySelector('.signupPagenonProfitDiv')
      .addEventListener('click', (evt) => {
        setFocusList(['Nonprofit'])
      })

    document
      .querySelector('.signupPageorganizationDiv')
      .addEventListener('click', (evt) => {
        setFocusList(['Organization'])
      })
  }, [])

  return (
    <div className="signupPagestepOne">
      <div className="signupPageentityText">
        <p>Select your entity</p>
      </div>
      <div tabIndex="1" name="individual" className="signupPageindividualDiv">
        <img src={individualSignUp} alt="individual-icon" />
        <p name="individual" className="signupPageindividual">
          Individual
        </p>
      </div>
      <div tabIndex="2" name="nonprofit" className="signupPagenonProfitDiv">
        <img src={nonprofitSignUp} alt="nonprofit-icon" />
        <p name="nonprofit" className="signupPagenonprofit">
          Nonprofit
        </p>
      </div>
      <div
        tabIndex="3"
        name="organization"
        className="signupPageorganizationDiv"
      >
        <img src={organizationSignUp} alt="organization-icon" />
        <p name="organization" className="signupPageorganization">
          Organization
        </p>
      </div>
      <div>
        <button
          type="submit"
          className="signupPagenextButton signupPagenextButtonEntry"
          onClick={deliverPath}
        >
          Next
        </button>
      </div>
    </div>
  )
}

EntryForm.propTypes = {
  stepUp: propTypes.func,
  individualPath: propTypes.func,
  orgPath: propTypes.func,
  nonProfitPath: propTypes.func,
}

export default EntryForm
