import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: {},
  token: null,
  loggedIn: false,
}

// this is the current up to date method to use for react-redux since createStore is deprecated
// this is essentially a reducer, you just export the actions, and the reducer
// you then add this to the store reducer object

const userSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    loggedIn: (state, action) => {
      state.loggedIn = action.payload
    },
    token: (state, action) => {
      state.token = action.payload
    },
    user: (state, action) => {
      state.user = action.payload
    },
  },
})

export const { loggedIn, token, user } = userSlice.actions
export default userSlice.reducer
