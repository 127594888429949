import React from 'react'
import './userFeed.css'
import { SummaryCarousel } from '../../components/carousel/index'
import {
  NavigationBar,
  NavigationMenuMobile,
} from '../../components/navigation'
import { Feed } from '../feed'
import SearchBar from '../../components/searchBar/searchBar'

import { useSelector } from 'react-redux'
import useROAsyncState from '../../hooks/useROAsyncState'

export const UserFeedHome = () => {
  const { user } = useSelector((store) => store.userInfo)

  const [notifications] = useROAsyncState([], `notifications/${user._id}/count`)

  if (!user) return <></>
  return (
    <div className="profile-page">
      <div className="header">
        <NavigationBar notifications={notifications} user={user} />
      </div>
      <div className="search-bar">
        <SearchBar />
      </div>
      <div className="summary-carousel">
        <SummaryCarousel />
      </div>
      <div className="user-feed">
        <Feed />
      </div>
      <div className="footer">
        <NavigationMenuMobile />
      </div>
    </div>
  )
}
