import React, { useState } from 'react'
import CausesRender from './CausesRender'
import propTypes from 'prop-types'
import { entityFinalStep, stepFour } from '../../../../assets'

const Causes = ({
  stepUp,
  stepDown,
  dataModify,
  data,
  differentiate,
  setDataObj,
}) => {
  const [chosenCauses, setChosenCauses] = useState([])

  const grabCauses = (causesData) => {
    setChosenCauses([...causesData])
  }

  const allCauses = () => {
    setChosenCauses([...causeList])
  }

  const causeList = [
    'Animal Care',
    'Arts & Culture',
    'Children & Youth',
    'Court Appointed Community Service',
    'Crisis & Emergency',
    'Education & Literacy',
    'Employment',
    'Entrepreneurial & Management',
    'Environment',
    'Faith & Religion',
    'Health & Wellness',
    'Homeless & Housing',
    'Hunger',
    'Immigrants & Refugees',
    'Justice & Legal',
    'People With Disabilities',
    'Race, Gender, & Ethnicity',
    'Seniors',
    'Sports & Recreation',
    'Substance Abuse',
    'Veterans & Military',
    'Women',
  ]
  let titleText = ''
  let imgSrc = ''
  const show = !!differentiate?.length

  if (differentiate) {
    imgSrc = entityFinalStep
    titleText = (
      <p>
        Select Up To <span className="blueText">2 Causes</span> Your
        {differentiate} Is Passionate About
      </p>
    )
  } else {
    imgSrc = stepFour
    titleText = <p>Select The Causes You Are Passionate About</p>
  }

  const populateUp = () => {
    setDataObj((data) => ({ ...data, causes: [...chosenCauses] }))
  }

  return (
    <div className="signupPagecausesExtends">
      <img className="stepFoursignUpForm entityFinalStepForm" src={imgSrc} />

      <div className="signupPagecausesText">{titleText}</div>
      <div className="signupPagecausesDiv">
        <CausesRender
          causes={causeList}
          typeUser={differentiate}
          grabData={grabCauses}
        />
      </div>

      {!show ? (
        <div className="signupPagecheckboxAll">
          <div>
            <input type="checkbox" onClick={allCauses} />
          </div>
          <div>
            <label htmlFor="checkboxAll">Select All</label>
          </div>
        </div>
      ) : (
        ''
      )}

      <div className="signupPagepaginationButtons">
        <div className="signupPagebuttonLeftColumnCauses">
          <button
            type="submit"
            className="signupPagepreviousButton"
            onClick={stepDown}
          >
            Previous
          </button>
        </div>
        <div className="signupPagebuttonRightColumnCauses" onClick={populateUp}>
          <button
            type="submit"
            className="signupPagenextButton"
            onClick={stepUp}
          >
            {differentiate ? 'Submit' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  )
}

Causes.propTypes = {
  stepUp: propTypes.func,
  stepDown: propTypes.func,
  dataModify: propTypes.func,
  data: propTypes.func,
  differentiate: propTypes.string,
  setDataObj: propTypes.func,
}

export default Causes
