import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import dbAPI from '../../util/dbAPI'
import { ProfilePicture } from '../../pages/feed/User/ProfilePicture'
import { emailIcon, magnifyingGlass } from '../../assets'
import propTypes from 'prop-types'
import SearchResults from '../../components/searchResults/SearchResults'

export const SearchResultsPage = ({
  urlSearchTerm = useLocation().search.split('?searchTerm=')[1],
}) => {
  const [dataLoaded, setDataLoaded] = useState()
  const [searchTerm, setSearchTerm] = useState()

  const handleEvts = (evt) => {
    const term = evt.target.value
    setSearchTerm(term)
  }

  useEffect(() => {
    const searchUsers = async () => {
      if (urlSearchTerm !== undefined) {
        let listOfMatchingUsers
        if (searchTerm) {
          listOfMatchingUsers = await dbAPI.searchUsers(searchTerm)
        } else {
          listOfMatchingUsers = await dbAPI.searchUsers(urlSearchTerm)
        }
        if (listOfMatchingUsers) {
          setDataLoaded(listOfMatchingUsers)
        }
      }
    }
    searchUsers()
  }, [urlSearchTerm, searchTerm])

  return (
    <div className="searchBarComponent">
      <div className="searchBar">
        <ProfilePicture classValue="searchBarProfilePicture" />
        <div className="searchInputArea">
          <label htmlFor="search" className="searchBarLabel">
            <img className="magnifyingGlass" src={magnifyingGlass} />
            <span> | </span>
          </label>
          <input
            type="text"
            id="searchTerm"
            name="searchTerm"
            onChange={handleEvts}
            placeholder={urlSearchTerm}
          />
        </div>
        <img className="emailIconSearchBar" src={emailIcon} />
      </div>
      <div className="grayPlaceholder"></div>
      <div className="fullListOfUsersLoaded">
        {dataLoaded && (
          <SearchResults searchResults={dataLoaded} fullPage={true} />
        )}
      </div>
    </div>
  )
}

export default SearchResultsPage

SearchResultsPage.propTypes = {
  urlSearchTerm: propTypes.string,
}
