import React from 'react'
import { FeedCard } from './feedCards/feedCard'
import './styles/feedStylesMobile.css'
import { Cta2, Houston, HeroImg } from '../../assets/index'

export const Feed = () => {
  // useEffect(async () => {
  //   const fetchUserFeed = async () => {
  //     // temp
  //     const result = await fetch('ENDPOINT')
  //     return result
  //   }

  //   const dataFetched = await fetchUserFeed()
  //
  //   const fetchCommentThreads = async(dataFetched) => {
  //     // gets all comments from a user post
  //     const comments = await fetch('ENDPOINT)
  //     return result
  //   }
  //
  //   const fetchChildrenComments = async () => {
  //     //get children of a comment, return em
  //   }
  //
  //
  //   /*
  //    *  Data should follow this format
  //    *  Content Type
  //    *  Text = 1, Photo = 2, MultiplePhotos = 3,
  //    *  TextWithPhoto = 4, TextWithMultiplePhotos = 5
  //    *
  //    *  VARNAME =
  //    *  {
  //    *    id: IDENTIFIER OF THE USER/STRING/NUMBER
  //    *    contentType: X,
  //    *    textContent: 'STRING',
  //    *    photoURL: ['FILE_LOCATION_IN_AWS'],
  //    *    likes: NUMBER,
  //    *    userLikedOwnPost: true/false,
  //    *    datePosted: //dateformat
  //    *  }
  //    *
  //    *  ContentType determines CSS classes to apply to content for proper formatting
  //    *  textContent && photoURL can be NULL/Undefined, since we might not always have these in a post
  //    */

  //   console.log(dataFetched)
  // }, [])

  const TestObject1 = {
    id: 'user1',
    contentType: 1,
    textContent: 'This is a test post',
    photoURL: [],
    likes: 5,
    userLikedOwnPost: false,
    datePosted: new Date().toUTCString(),
  }
  const TestObject2 = {
    id: 'user2',
    contentType: 2,
    textContent: '',
    photoURL: [Cta2],
    likes: 10,
    userLikedOwnPost: false,
    datePosted: new Date().toUTCString(),
  }
  const TestObject3 = {
    id: 'user3',
    contentType: 3,
    textContent: '',
    photoURL: [Cta2, Houston, HeroImg],
    likes: 45,
    userLikedOwnPost: true,
    datePosted: new Date().toUTCString(),
  }
  const TestObject4 = {
    id: 'user4',
    contentType: 4,
    textContent: 'This is a test post',
    photoURL: [Houston],
    likes: 25,
    userLikedOwnPost: true,
    datePosted: new Date().toUTCString(),
  }
  const TestObject5 = {
    id: 'user5',
    contentType: 5,
    textContent:
      'Lorem ipsum sapien nec sagittis aliquam malesuada bibendum arcu. At auctor urna nunc id cursus. Nec ullamcorper sit amet risus nullam eget felis eget nunc. Duis convallis convallis tellus id interdum velit. Viverra orci sagittis eu volutpat odio facilisis mauris sit amet. Feugiat nibh sed pulvinar proin gravida hendrerit.',
    photoURL: [Cta2, Houston, HeroImg],
    likes: 5,
    userLikedOwnPost: false,
    datePosted: new Date().toUTCString(),
  }

  return (
    <div className="userFeed">
      <FeedCard
        identifier={TestObject1.id}
        contentType={TestObject1.contentType}
        textContent={TestObject1.textContent}
        photoURL={TestObject1.photoURL}
        datePosted={TestObject1.datePosted}
        likes={TestObject1.likes}
        userLikedOwnPost={TestObject1.userLikedOwnPost}
      />
      <FeedCard
        identifier={TestObject2.id}
        contentType={TestObject2.contentType}
        textContent={TestObject2.textContent}
        photoURL={TestObject2.photoURL}
        datePosted={TestObject2.datePosted}
        likes={TestObject2.likes}
        userLikedOwnPost={TestObject2.userLikedOwnPost}
      />
      <FeedCard
        identifier={TestObject3.id}
        contentType={TestObject3.contentType}
        textContent={TestObject3.textContent}
        photoURL={TestObject3.photoURL}
        datePosted={TestObject3.datePosted}
        likes={TestObject3.likes}
        userLikedOwnPost={TestObject3.userLikedOwnPost}
      />
      <FeedCard
        identifier={TestObject4.id}
        contentType={TestObject4.contentType}
        textContent={TestObject4.textContent}
        photoURL={TestObject4.photoURL}
        datePosted={TestObject4.datePosted}
        likes={TestObject4.likes}
        userLikedOwnPost={TestObject4.userLikedOwnPost}
      />
      <FeedCard
        identifier={TestObject5.id}
        contentType={TestObject5.contentType}
        textContent={TestObject5.textContent}
        photoURL={TestObject5.photoURL}
        datePosted={TestObject5.datePosted}
        likes={TestObject5.likes}
        userLikedOwnPost={TestObject5.userLikedOwnPost}
      />
    </div>
  )
}
