import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import dbAPI from '../../../util/dbAPI'
import Availability from './forms/Availability'
import Causes from './forms/Causes'
import EntityInfo from './forms/EntityInfo'
import EntitySignUp from './forms/EntitySignUp'
import EntryForm from './forms/EntryForm'
import UserInfo from './forms/UserInfo'
import UserSignUp from './forms/UserSignUp'

import { useDispatch } from 'react-redux'
import { user, loggedIn, token } from '../../../redux/userReducer'

const Render = () => {
  //  Renders the props passed to it.  Can add more functionality if we want to connect a redux store later on
  const [formSteps, setFormsteps] = useState([])
  const [dataObj, setDataObj] = useState({})
  const [path, setPath] = useState(0)
  const [display, setDisplay] = useState()
  const [entityName, setEntityName] = useState('Entity')
  const Entity = [EntryForm, EntityInfo, EntitySignUp, Causes]
  const User = [EntryForm, UserInfo, UserSignUp, Causes, Availability]
  let [step, setStep] = useState(0)

  const history = useHistory()
  const dispatch = useDispatch()

  const getCurrentData = () => {
    return dataObj
  }

  const componentFactory = () => {
    // Sets up the paths a user can take through the form
    const pickFormPath = () => {
      const componentHolder = []
      componentHolder.push(
        Entity.map((FormComponent) => {
          const keyProp = Math.ceil(Math.random() * 9999)
          return (
            <FormComponent
              key={keyProp}
              stepUp={stepForward}
              stepDown={stepBackwards}
              dataModify={setDataObj}
              data={getCurrentData}
              differentiate={entityName}
              setDataObj={setDataObj}
            />
          )
        })
      )
      componentHolder.push(
        User.map((FormComponent) => {
          const keyProp = Math.ceil(Math.random() * 9999)
          return (
            <FormComponent
              key={keyProp}
              stepUp={stepForward}
              stepDown={stepBackwards}
              dataModify={setDataObj}
              data={getCurrentData}
              setDataObj={setDataObj}
            />
          )
        })
      )
      setFormsteps([componentHolder])
    }

    pickFormPath()
    setDisplay(
      <EntryForm
        stepUp={stepForward}
        individualPath={setIndividualPath}
        orgPath={organizationPath}
        nonProfitPath={nonProfitPath}
        setDataObj={setDataObj}
      />
    )
  }

  const handleSubmit = async (evt) => {
    // Displays next part of the multidimensional array, aka traveling down a path
    evt.preventDefault()
    setDisplay(formSteps[0][path][step])
    console.log(dataObj)
    console.log(entityName, step)

    if (entityName !== 'Individual' && step === 4) {
      const registerResults = await dbAPI.register(dataObj)
      console.log(registerResults)
      if (registerResults.data.token) {
        // TODO: save token to redux
        // save orginization to redux, do we need to create a seperate slice for this?
        window.location.href = 'http://localhost:3000/feed'
      }
    }
    if (entityName === 'Individual' && step === 5) {
      const registerResults = await dbAPI.register(dataObj)
      if (registerResults.data.token) {
        dispatch(user(registerResults.data.user))
        dispatch(token(registerResults.data.token))
        dispatch(loggedIn(true))
        history.push('/feed')
      }
    }
  }

  const stepForward = () => {
    setStep((step += 1))
  }

  const stepBackwards = () => {
    setStep((step -= 1))
    if (step === 0) {
      componentFactory()
    }
  }

  const setIndividualPath = () => {
    setPath(1)
    setEntityName('Individual')
  }

  const organizationPath = () => {
    setPath(0)
    setEntityName('Organization')
  }

  const nonProfitPath = () => {
    setPath(0)
    setEntityName('Nonprofit')
  }

  useEffect(() => {
    componentFactory()
  }, [])

  return (
    <div className="signupPagesignupForm">
      <form className="signupPageformPlacement" onSubmit={handleSubmit}>
        {display}
      </form>
    </div>
  )
}

Render.propTypes = {}

export default Render
