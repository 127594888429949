import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { CloseButton } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import API from '../../../../util/dbAPI'
import './style.css'

function Unsubscribe(props) {
  const [showConfirm, setShowConfirm] = useState(true)
  const [showReason, setShowReason] = useState(false)
  const [otherReason, setOtherReason] = useState()
  const [reason, setReason] = useState()

  const history = useHistory()

  const emailProp = JSON.stringify(props)
  const emailStr = emailProp.split('"')
  const [email] = useState(emailStr[3])

  const handleCloseConfirm = () => setShowConfirm(false)
  const handleCloseReason = () => setShowReason(false)
  const handleShowReason = () => setShowReason(true)

  const handleCloseBtn = () => {
    history.push('/')
  }

  const handleConfirmSubmit = (e) => {
    e.preventDefault()
    handleCloseConfirm()
    // API call here to delete from DB

    API.unsubscribe(email, null)
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
    handleShowReason()
  }

  const handleSubmitReason = (e) => {
    e.preventDefault()
    let reasonObj
    if (otherReason) {
      reasonObj = {
        reason: otherReason,
      }
    } else {
      reasonObj = {
        reason: reason,
      }
    }
    console.log(email, reasonObj)
    // API call to insert email and reason to reason for unsubscribe table
    API.unsubscribe(email, reasonObj)
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
    handleCloseReason()
  }

  const reasonForm = () => {
    return (
      <>
        <Form id="unsubForm">
          <fieldset>
            <Form.Group as={Row}>
              <Col>
                <Form.Check
                  type="radio"
                  label="I no longer want to receive these emails."
                  name="dontWant"
                  id="dontWant"
                  value="dontWant"
                  checked={reason === 'dontWant'}
                  onChange={(e) => {
                    setReason(e.target.value)
                  }}
                />
                <Form.Check
                  type="radio"
                  label="I never signed up for this mailing list."
                  name="didntSignup"
                  id="didntSignup"
                  value="didntSignup"
                  checked={reason === 'didntSignup'}
                  onChange={(e) => {
                    setReason(e.target.value)
                  }}
                />
                <Form.Check
                  type="radio"
                  label="Other (please fill in the reason below)."
                  name="other"
                  id="other"
                  value="other"
                  checked={reason === 'other'}
                  onChange={(e) => setReason(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Col} id="unsubFormTextInput">
              {reason === 'other' ? (
                <Form.Control
                  type="textarea"
                  className="col-6"
                  placeholder="Please input your reason"
                  id="otherText"
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                />
              ) : (
                <Form.Control
                  disabled={true}
                  type="textarea"
                  className="col-6"
                  placeholder="Please input your reason"
                  id="otherText"
                  value=""
                />
              )}
            </Form.Group>
          </fieldset>
        </Form>
      </>
    )
  }

  return (
    <>
      <Modal
        show={showConfirm}
        onHide={handleCloseConfirm}
        backdrop="static"
        keyboard={false}
      >
        <Container className="modalHeaderCloseButton">
          <CloseButton onClick={handleCloseBtn} />
        </Container>

        <Modal.Body id="confirmModalBody">
          <div className="modalTitle">Are You Sure?</div>
          <p className="modal-text">
            We just want to make sure that you are fully ready to unsubscribe.
            We’d love to keep you as part of the Darbe community!
          </p>
        </Modal.Body>

        <Modal.Footer as={Container} id="confirmModalFooter">
          <Row className="buttonRows">
            <Button
              id="stay"
              className="orangeButton"
              variant="secondary"
              onClick={handleCloseBtn}
            >
              I&apos;ll Stay
            </Button>
          </Row>
          <Row className="buttonRows">
            <Button
              className="orangeButton btn-outline"
              variant="secondary"
              onClick={handleConfirmSubmit}
            >
              Unsubscribe
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showReason}
        onHide={handleCloseReason}
        backdrop="static"
        keyboard={false}
      >
        <Container className="modalHeaderCloseButton">
          <CloseButton onClick={handleCloseBtn} />
        </Container>

        <Modal.Body>
          <div className="modalTitle">You Have Been Unsubscribed!</div>
          <p className="modal-text">
            Your email was successfully unsubscribed from our mailing list. If
            you have a moment, please let us know why you unsubscribed.
          </p>
          {reasonForm()}
        </Modal.Body>
        <Modal.Footer id="unsubModalFooter">
          {reason ? (
            <Button
              className="orangeButton"
              variant="secondary"
              onClick={handleSubmitReason}
            >
              Submit
            </Button>
          ) : (
            <Button className="orangeButton" variant="secondary" disabled>
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default Unsubscribe
