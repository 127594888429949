import React from 'react'
import { facebook, twitter, instagram, linkedIn } from '../../../../assets'
import './style.css'

function Footer() {
  return (
    <footer className="container my-5" id="ContactUs">
      <div className="row justify-content-center">
        <div
          className="col-xl-6 col-lg-5 order-sm-2 order-1 px-lg-5"
          id="consec"
        >
          <p>
            Still have questions? <span className="bld">Contact Us.</span>
          </p>
          <a href="mailto: info@darbe.co">info@darbe.co</a>
          <br />
          <br />
          <br />
          <p>
            Copyrights 2021 Darbe | All Rights Reserved |{' '}
            <a href="/privacyPolicy">Privacy Policy</a>{' '}
          </p>
        </div>
        <div className="col-xl-4 col-lg-5 offset-lg-2 offset-xl-2 order-sm-1 order-lg-2 mt-lg-5 mb-5 mobile">
          <a
            href="https://www.instagram.com/darberocks/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="instagram" className="mx-sm-3 Smi" />
          </a>
          <a
            href="https://www.facebook.com/darberocks"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="facebook" className="mx-sm-3 Smi" />
          </a>
          <a
            href="https://www.linkedin.com/company/darbe/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedIn} alt="linkedIn" className="mx-sm-3 Smi" />
          </a>
          <a
            href="https://twitter.com/darberocks"
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitter} alt="twitter" className="mx-sm-3 Smi" />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
