import React from 'react'
import './PlaneIcon.css'
import { plane } from '../../../../assets/'

const PlaneIcon = () => {
  return (
    <div className={'CreatePostPlaneIconBorder'}>
      <img src={plane} className="CreatePostPlaneIcon" />
    </div>
  )
}

export default PlaneIcon
