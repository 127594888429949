import { useEffect } from 'react'

// customer async function that uses useEffect to make our async calls to out api

function useAsync(f, deps = []) {
  useEffect(() => {
    ;(async () => {
      await f()
    })()
  }, deps)
}

export { useAsync }
