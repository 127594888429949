import * as React from 'react'

const SvgPost = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M28.444 0H3.556A3.555 3.555 0 0 0 0 3.556v24.888A3.555 3.555 0 0 0 3.556 32h24.888C30.4 32 32 30.4 32 28.444V3.556C32 1.6 30.4 0 28.444 0ZM24.89 17.778h-7.111v7.11h-3.556v-7.11h-7.11v-3.556h7.11v-7.11h3.556v7.11h7.11v3.556Z"
    />
  </svg>
)

export default SvgPost
