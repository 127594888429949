import React, { useState } from 'react'
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel'
import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
// import { FaRegCircle } from 'react-icons/fa'
import 'pure-react-carousel/dist/react-carousel.es.css'
import './style.css'

export const SummaryCarousel = () => {
  // consolidated data to one object in a use state, most of the data will be coming from an api
  const [userSummeries] = useState([
    {
      index: 0,
      slideTitle: 'Event',
      body: [
        {
          title: 'Volunteer Hours',
          amount: 3,
        },
        {
          title: 'Volunteer $ Value',
          amount: '$ 72',
        },
        {
          title: 'Orgs Supported',
          amount: 3,
        },
      ],
    },
    {
      index: 1,
      slideTitle: 'Match',
      body: [
        {
          title: 'Event Matches',
          amount: 3,
        },
        {
          title: 'Preference Matches',
          amount: 5,
        },
        {
          title: 'Availability Matches',
          amount: 3,
        },
      ],
    },
    {
      index: 2,
      slideTitle: 'Impact',
      body: [
        {
          title: 'People Fed',
          amount: 207,
        },
        {
          title: 'Animals Rescued',
          amount: 18,
        },
        {
          title: 'Houses Built',
          amount: 14,
        },
      ],
    },
  ])

  const renderSlideColumn = (count, title) => {
    return (
      <div>
        <div className={'carousel-number-container'}>{count}</div>
        <div className="carousel-number-title">{title}</div>
      </div>
    )
  }

  return (
    <div style={{ width: '100vw' }}>
      <CarouselProvider
        naturalSlideWidth={375}
        naturalSlideHeight={175}
        totalSlides={3}
        touchEnabled
        dragEnabled
        style={{
          backgroundColor: '#FAF9F9',
          height: '225px',
        }}
        visibleSlides={1}
      >
        <Slider>
          {userSummeries.map((summary) => (
            <Slide index={summary.index} key={summary.index}>
              <Row className="carousel-slide-label">
                {summary.slideTitle} Summary
              </Row>
              <Row style={{ justifyContent: 'space-evenly' }}>
                {/* it makes more sense to iterate through data than hard coding it */}
                {renderSlideColumn(
                  summary.body[0].amount,
                  summary.body[0].title
                )}
                {renderSlideColumn(
                  summary.body[1].amount,
                  summary.body[1].title
                )}
                {renderSlideColumn(
                  summary.body[2].amount,
                  summary.body[2].title
                )}
              </Row>
            </Slide>
          ))}
        </Slider>
        <div
          style={{ display: 'flex', justifyContent: 'center', paddingBlock: 8 }}
        >
          <DotGroup className="prc-dotGroup" />
        </div>
      </CarouselProvider>
    </div>
  )
}
