import {
  appleIcon,
  googleIcon,
  fancyLine,
  passwordHideIcon,
  passwordShowIcon,
  stepTwo,
} from '../../../../assets'
import React, { useState } from 'react'
import propTypes from 'prop-types'
import dbAPI from '../../../../util/dbAPI'

const EntityInfo = ({
  stepUp,
  stepDown,
  dataModify,
  data,
  differentiate,
  setDataObj,
}) => {
  const [passwordShow, setPasswordShow] = useState(true)
  const [passwordShowTwo, setPasswordShowTwo] = useState(true)
  const [entityData, setEntityData] = useState({})

  const handleChange = (evt) => {
    const { name, value } = evt.target
    setEntityData({ ...entityData, [name]: value })
  }

  const populateUp = () => {
    setDataObj((data) => ({ ...data, ...entityData }))
  }

  const OAuthStart = async (evt) => {
    evt.preventDefault()

    evt.target.id === 'google' ? dbAPI.googleOAuth() : dbAPI.appleOAuth()
  }

  const changeIcons = () => {
    //  for hide and show password
    const divInput = document.querySelector('.signupPagepasswordInput input')
    const imageElement = document.querySelector('#entityInfoPassword')

    if (passwordShow) {
      divInput.setAttribute('type', 'text')
      imageElement.src = passwordShowIcon
    }
    if (!passwordShow) {
      divInput.setAttribute('type', 'password')
      imageElement.src = passwordHideIcon
    }

    setPasswordShow((passwordShow) => !passwordShow)
  }

  const changeIconsTwo = () => {
    //  for hide and show password
    const divInput = document.querySelector('.confirmPassword input')
    const imageElement = document.querySelector('#entityInfoPasswordConfirm')

    if (passwordShowTwo) {
      divInput.setAttribute('type', 'text')
      imageElement.src = passwordShowIcon
    }
    if (!passwordShowTwo) {
      divInput.setAttribute('type', 'password')
      imageElement.src = passwordHideIcon
    }

    setPasswordShowTwo((passwordShowTwo) => !passwordShowTwo)
  }

  const placeholderEmail = `${differentiate}@email.com`
  return (
    <div>
      <img className="stepTwosignUpForm" src={stepTwo} />
      <div className="signupPageuserInfoForm entitySignupForm">
        <div className="columnEntityAdjustment">
          <div className="signupPageemailInput userForm">
            <label htmlFor="email">{differentiate} Email</label>
            <input
              type="email"
              name="email"
              placeholder={placeholderEmail}
              onChange={handleChange}
              required
            />
          </div>

          <div className="signupPagepasswordInput userForm">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="entityInfoPassword"
              name="passwordOne"
              placeholder="● ● ● ● ● ● ● ● ●"
              onChange={handleChange}
              required
            />
            <img
              onClick={changeIcons}
              id="passwordIcon"
              className="loginPagepasswordIcon"
              src={passwordHideIcon}
            />
          </div>

          <div className="signupPagepasswordInput userForm confirmPassword">
            <label htmlFor="password">Re-enter Password</label>
            <input
              type="password"
              id="entityInfoPasswordConfirm"
              name="passwordTwo"
              placeholder="● ● ● ● ● ● ● ● ●"
              onChange={handleChange}
              required
            />
            <img
              onClick={changeIconsTwo}
              id="passwordIcon"
              className="loginPagepasswordIcon"
              src={passwordHideIcon}
            />
          </div>
        </div>

        <div className="signupPagelines">
          <img src={fancyLine} className="fancyLines"></img>
          <p className="wordBetweenLines"> or </p>
          <img src={fancyLine} className="fancyLines"></img>
        </div>

        <div className="signupPageOAUthButtonVertical">
          <div className="signupPageOAuthButtons">
            <img src={googleIcon} className="googleIcon"></img>
            <button
              className="googleOAuthButton"
              id="google"
              onClick={OAuthStart}
            >
              {' '}
              Log in with Google
            </button>

            <img src={appleIcon} className="appleIcon"></img>
            <button
              className="appleOAuthButton"
              id="apple"
              onClick={OAuthStart}
            >
              {' '}
              Log in with Apple
            </button>
          </div>
        </div>
      </div>
      <div className="entitySignupForm signupPagepaginationButtons">
        <div className="signupPagebuttonLeftColumn">
          <button className="signupPagepreviousButton" onClick={stepDown}>
            Previous
          </button>
        </div>
        <div className="signupPagebuttonRightColumn" onClick={populateUp}>
          <button className="signupPagenextButton" onClick={stepUp}>
            Next
          </button>
        </div>
      </div>
    </div>
  )
}

EntityInfo.propTypes = {
  stepUp: propTypes.func,
  stepDown: propTypes.func,
  dataModify: propTypes.func,
  data: propTypes.func,
  differentiate: propTypes.string,
  setDataObj: propTypes.func,
}

export default EntityInfo
