import { useReducer } from "react";
import { UPDATE_TYPE } from "./actions";

export const reducer = (state, action) => {
    switch(action.type) {
        case UPDATE_TYPE:
            return {
                ...state,
                entity: action.entity

            }
        default:
            return state;
    }
}

export function useSignUpReducer(initalState) {
    return useReducer(reducer, initalState)
}