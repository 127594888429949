import React, { useState } from 'react'
import propTypes from 'prop-types'
import { DropdownList } from '../helpers/DropdownList'
import { stepThree, closeDropDown, openDropDown } from '../../../../assets'

const UserSignUp = ({ stepUp, stepDown, dataModify, data, setDataObj }) => {
  const [entityData, setEntityData] = useState({})
  const [toggleDays, setToggleDays] = useState(false)
  const [toggleMonths, setToggleMonths] = useState(false)
  const [toggleYears, setToggleYears] = useState(false)
  const [dropDownIconShow, setdropDownIconShow] = useState(true)
  const [dayValue, setDayValue] = useState()
  const [monthValue, setMonthValue] = useState()
  const [yearValue, setYearValue] = useState()
  const day = 'day'
  const month = 'month'
  const year = 'year'

  const handleChange = (evt) => {
    const { name, value } = evt.target
    setEntityData({ ...entityData, [name]: value })
  }

  const populateUp = () => {
    const dob = { month: monthValue, day: dayValue, year: yearValue }
    setDataObj((data) => ({ ...data, ...entityData, ...dob }))
  }

  const toggleDropDownMenu = (evt) => {
    const { name } = evt.target

    if (name === 'month') {
      setToggleMonths((toggleMonths) => !toggleMonths)
    }
    if (name === 'year') {
      setToggleYears((toggleYears) => !toggleYears)
    }
    if (name === 'day') {
      setToggleDays((toggleDays) => !toggleDays)
    }
    changeIcons(name)
  }

  const resetToggles = (name) => {
    if (toggleMonths) {
      setToggleMonths((toggleMonths) => !toggleMonths)
      changeIcons(name)
    }
    if (toggleDays) {
      setToggleDays((toggleDays) => !toggleDays)
      changeIcons(name)
    }
    if (toggleYears) {
      setToggleYears((toggleYears) => !toggleYears)
      changeIcons(name)
    }
  }

  const changeIcons = (name) => {
    const imageElement = document.querySelector(
      `.signupPage${name} .dropDownArrowPlacement`
    )

    if (dropDownIconShow) {
      imageElement.src = closeDropDown
    }
    if (!dropDownIconShow) {
      imageElement.src = openDropDown
    }

    setdropDownIconShow((dropDownIconShow) => !dropDownIconShow)
  }

  return (
    <div>
      <img className="stepThreesignUpForm" src={stepThree} />
      <div className="signupPageuserSignUpForm">
        <div className="signupPagerow">
          <div className="signupPagecolumn signupPagefirstName">
            <label htmlFor="text">First Name</label>
            <input
              type="text"
              name="firstName"
              placeholder="John"
              onChange={handleChange}
            />
          </div>

          <div className="signupPagecolumn signupPagelastName">
            <label htmlFor="text">Last Name</label>
            <input
              type="text"
              name="lastName"
              placeholder="Smith"
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="signupPagerow">
          <div className="signupPagecolumn signupPagecity">
            <label htmlFor="city">City</label>
            <input
              type="text"
              name="city"
              placeholder="Your City"
              onChange={handleChange}
            />
          </div>
          <div className="signupPagecolumn signupPagezipcode">
            <label htmlFor="zipcode">Zipcode</label>
            <input
              type="text"
              name="zipcode"
              placeholder="Your Zipcode"
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="signupPagerow">
          <label className="signupPagedobUser" htmlFor="dobUser">
            Date Of Birth
          </label>
        </div>

        <div className="signupPagerow">
          <div className="signupPagecolumn signupPagemonth">
            <input type="text" name="month" value={monthValue} />
            <img
              className="dropDownArrowPlacement"
              name="month"
              src={openDropDown}
              onClick={toggleDropDownMenu}
            />
            {toggleMonths ? (
              <DropdownList
                typeDisplay={month}
                resetFunc={resetToggles}
                setValue={setMonthValue}
              />
            ) : (
              ''
            )}
          </div>
          <div className="signupPagecolumn signupPageday">
            <input type="text" name="days" value={dayValue} />
            <img
              className="dropDownArrowPlacement"
              name="day"
              src={openDropDown}
              onClick={toggleDropDownMenu}
            />
            {toggleDays ? (
              <DropdownList
                typeDisplay={day}
                resetFunc={resetToggles}
                setValue={setDayValue}
              />
            ) : (
              ''
            )}
          </div>
          <div className="signupPagecolumn signupPageyear">
            <input type="text" name="year" value={yearValue} />
            <img
              className="dropDownArrowPlacement"
              name="year"
              src={openDropDown}
              onClick={toggleDropDownMenu}
            />
            {toggleYears ? (
              <DropdownList
                typeDisplay={year}
                resetFunc={resetToggles}
                setValue={setYearValue}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className="userSignupMoreInfo signupPagepaginationButtons">
        <div className="signupPagebuttonLeftColumn">
          <button
            type="submit"
            className="signupPagepreviousButton"
            onClick={stepDown}
          >
            Previous
          </button>
        </div>
        <div className="signupPagebuttonRightColumn" onClick={populateUp}>
          <button
            type="submit"
            className="signupPagenextButton"
            onClick={stepUp}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}

UserSignUp.propTypes = {
  stepUp: propTypes.func,
  stepDown: propTypes.func,
  dataModify: propTypes.func,
  data: propTypes.func,
  setDataObj: propTypes.func,
}

export default UserSignUp
