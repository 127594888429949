import React from 'react'

const PrivPol = () => {
  return (
    <div className="container p-4">
      <h1 className="text-center">ONLINE PRIVACY POLICY AGREEMENT</h1>
      <hr />
      <p>
        Darbe Rocks Inc, (Darbe), is committed to keeping any and all personal
        information collected of those individuals that visit our website and
        make use of our online facilities and services accurate, confidential,
        secure and private. <br /> Our privacy policy has been designed and
        created to ensure those affiliated with Darbe Inc of our commitment and
        realization of our obligation not only to meet but to exceed most
        existing privacy standards.
        <br />
        THEREFORE, this Privacy Policy Agreement shall apply to Darbe Rocks Inc,
        and thus it shall govern any and all data collection and usage thereof.{' '}
        <br />
        Through the use of www.Darbe.co you are herein consenting to the
        following data procedures expressed within this agreement.
        <br />
        It is highly recommended and suggested that you review the privacy
        policies and statements of any website you choose to use or frequent as
        a means to better understand the way in which other websites garner,
        make use of and share information collected.
        <br />
        <br />
        <hr />
        Collection of Information
        <br />
        This website collects various types of information, such as:
      </p>
      <ul>
        <li>
          Voluntarily provided information which may include your name, address,
          email address, billing and/or credit card information etc., which may
          be used when you purchase products and/or services and to deliver the
          services you have requested.
        </li>
        <li>
          Information automatically collected when visiting our website, which
          may include cookies, third party tracking technologies and server
          logs.
        </li>
      </ul>
      <p>
        Please rest assured that this site shall only collect personal
        information that you knowingly and willingly provide by way of surveys,
        completed membership forms, and emails. <br /> It is the intent of this
        site to use personal information only for the purpose for which it was
        requested and any additional uses specifically provided on this site.
        <br />
        Darbe may have the occasion to collect non-personal anonymous
        demographic information, such as age, gender, household income,
        political affiliation, race and religion, as well as the type of browser
        you are using, IP address, type of operating system, at a later time,
        that will assist us in providing and maintaining superior quality
        service.
        <br />
        <hr />
        Use of Information Collected:
        <br />
        Darbe may collect and may make use of personal information to assist in
        the operation of our website and to ensure delivery of the services you
        need and request. <br /> At times, we may find it necessary to use
        personally identifiable information as a means to keep you informed of
        other possible products and/or services that may be available to you
        from www.Darbe.com. <br /> Darbe Inc may also be in contact with you
        with regards to completing surveys and/or research questionnaires
        related to your opinion of current or potential future services that may
        be offered.
        <br />
        <br />
        Darbe does not now, nor will it in the future, sell, rent or lease any
        of its customer lists and/or names to any third parties.
        <br />
        <br />
        Darbe Rocks Inc, may feel it necessary, from time to time, to make
        contact with you on behalf of our other external business partners with
        regards to a potential new offer which may be of interest to you. If you
        consent or show interest in presented offers, then, at that time,
        specific identifiable information, such as name, email address and/or
        telephone number, may be shared with the third party.
        <br />
        <br />
        Darbe may deem it necessary to follow websites and/or pages that their
        users may frequent in an effort to gleam what types of services and/or
        products may be the most popular to customers or the general public.
        <br />
        <br />
        Darbe may disclose your personal information, without any prior notice
        to you, unless required to do in accordance to applicable laws and/or in
        a good faith belief that such action is deemed necessary or required in
        an effort to:
      </p>
      <ul>
        <li>
          Remain in conformance with any decrees, laws and/or statutes or in an
          effort to comply with any process which may be served upon Darbe
          Incand/or its website;
        </li>
        <li>
          Maintain, safeguard and/or preserve all the rights and/or property of
          Darbe Inc; and
        </li>
        <li>
          Perform under demanding conditions in an effort to safeguard the
          personal safety of users of www.Darbe.co and/or the general public.
        </li>
      </ul>
      <p>
        <hr />
        Non-Marketing Purposes:
        <br />
        Darbe Incgreatly respects your privacy. We do maintain and reserve the
        right to contact you if needed for non-marketing purposes (such as bug
        alerts, security breaches, account issues, and/or changes in Darbe Inc
        products and services). In certain circumstances, we may use our
        website, newspapers, or other public means to post a notice.
        <hr />
        Children Under Age of 13:
        <br />
        Darbe does not knowingly collect personal identifiable information from
        children under the age of thirteen (13) without verifiable parental
        consent. If it is determined that such information has been
        inadvertently collected on anyone under the age of thirteen (13), we
        shall immediately take the necessary steps to ensure that such
        information is deleted from our system&apos;s database. Anyone under the
        age of thirteen (13) must seek and obtain parent or guardian permission
        to use this website.
        <hr />
        Unsubscribe or Opt-Out:
        <br />
        All users and/or visitors to our website have the option to discontinue
        receiving communication from us and/or reserve the right to discontinue
        receiving communications by way of email or newsletters. To discontinue
        or unsubscribe to our website please send an email that you wish to
        unsubscribe to accounts@Darbe.com. If you wish to unsubscribe or opt-out
        from any third party websites, you must go to that specific website to
        unsubscribe and/or opt-out. Darbe Incwill continue to adhere to the
        privacy policy described herein with respect to any personal information
        previously collected.
        <hr />
        Links to Other Web Sites:
        <br />
        Our website does contain links to affiliate and other websites. Darbe
        does not claim nor accept responsibility for any privacy policies,
        practices and/or procedures of other such websites. Therefore, we
        encourage all users and visitors to be aware when they leave our website
        and to read the privacy statements of each and every website that
        collects personally identifiable information. The aforementioned Privacy
        Policy Agreement applies only and solely to the information collected by
        our website.
        <hr />
        Security:
        <br />
        Darbe Inc shall endeavor and shall take every precaution to maintain
        adequate physical, procedural and technical security with respect to its
        offices and information storage facilities so as to prevent any loss,
        misuse, unauthorized access, disclosure or modification of the
        user&apos;s personal information under our control.
        <br />
        The company also uses Secure Socket Layer (SSL) for authentication and
        private communications in an effort to build users&apos; trust and
        confidence in the internet and website use by providing simple and
        secure access and communication of credit card and personal information.
        <hr />
        Changes to Privacy Policy Agreement:
        <br />
        Darbe reserves the right to update and/or change the terms of our
        privacy policy, and as such we will post those changes to our website,
        so that our users and/or visitors are always aware of the type of
        information we collect, how it will be used, and under what
        circumstances, if any, we may disclose such information. <br /> If at
        any point in time Darbe Inc decides to make use of any personally
        identifiable information on file, in a manner vastly different from that
        which was stated when this information was initially collected, the user
        or users shall be promptly notified by email. <br /> Users at that time
        shall have the option as to whether or not to permit the use of their
        information in this separate manner.
        <hr />
        Acceptance of Terms: Through the use of this website, you are hereby
        accepting the terms and conditions stipulated within the aforementioned
        Privacy Policy Agreement. <br /> If you are not in agreement with our
        terms and conditions, then you should refrain from further use of our
        sites. In addition, your continued use of our website following the
        posting of any updates or changes to our terms and conditions shall mean
        that you are in agreement and acceptance of such changes.
        <hr />
        How to Contact Us:
        <br />
        If you have any questions or concerns regarding the Privacy Policy
        Agreement related to our website, please feel free to contact us at the
        following email, telephone number or mailing address.
        <br />
        <a href="mailto:Info@Darbe.co">Info@Darbe.co</a>
        <br />
        August 04, 2021
      </p>
    </div>
  )
}

export default PrivPol
