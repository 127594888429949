import React, { useState } from 'react'
import { useHistory } from 'react-router'
import {
  Logo,
  passwordHideIcon,
  passwordShowIcon,
  appleIcon,
  googleIcon,
  fancyLine,
} from '../../../assets'
import Error from './Error'
import '../styles/style.css'
import dbAPI from '../../../util/dbAPI'

import { useDispatch } from 'react-redux'
import { user, loggedIn, token } from '../../../redux/userReducer'

const Login = () => {
  const [loginInfo, setLoginInfo] = useState()
  const [errors, setErrors] = useState([])
  const [passwordShow, setPasswordShow] = useState(true)
  const [validPassCheck, setvalidPassCheck] = useState('')
  const [validEmail, setValidEmail] = useState()
  const [validPass, setValidPass] = useState()

  const dispatch = useDispatch()
  const history = useHistory()

  const validEmailLogin = 'validEmailLogin'
  const validPassLogin = 'validPassLogin'
  const invalidEmailLogin = 'invalidEmailLogin'
  const invalidPassLogin = 'invalidPassLogin'

  const setClassNames = () => {
    const emailInput = document.querySelector('#email')
    const passwordInput = document.querySelector('#password')

    if (validEmail) {
      emailInput.classList = validEmailLogin
    }
    if (validPass) {
      passwordInput.classList = validPassLogin
    }
    if (!validEmail) {
      emailInput.classList = invalidEmailLogin
    }
    if (!validPass) {
      passwordInput.classList = invalidPassLogin
    }
  }

  const handleEvts = (evt) => {
    const { name, value } = evt.target

    setLoginInfo((loginInfo) => ({
      ...loginInfo,
      [name]: value,
    }))

    if (name === 'password') {
      setvalidPassCheck(value)
    }
  }

  const validate = (data) => {
    if (!data) {
      return
    }

    const { email, password } = data
    const noPassLength = !password
    const noEmailLength = !email

    if (noPassLength || password.length < 6) {
      setErrors((errors) => [
        ...errors,
        'Password does not meet length requirement',
      ])
      setValidPass(false)
    }
    if (noEmailLength || email.length < 6) {
      setErrors((errors) => [...errors, 'Email is invalid'])
      setValidEmail(false)
    }
    if (!noEmailLength && email.length > 6) {
      setValidEmail(true)
    }
    if (!noPassLength && password.length > 6) {
      setValidPass(true)
    }
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault()

    if (!loginInfo) {
      setErrors(() => [...errors, 'No information entered!'])
      return
    }

    setErrors([])
    validate(loginInfo)
    setClassNames()
    const loginSuccesful = await dbAPI.login(
      loginInfo.email,
      loginInfo.password
    )

    if (loginSuccesful.data.token) {
      dispatch(user(loginSuccesful.data.user))
      dispatch(token(loginSuccesful.data.token))
      dispatch(loggedIn(true))
      history.push('/feed')
    } else {
      setErrors(() => [...errors, `${loginSuccesful.response.statusText}`])
    }
  }

  const changeIcons = () => {
    //  for hide and show password
    const divInput = document.querySelector('.loginPagepasswordInput input')
    const imageElement = document.querySelector('#passwordIcon')

    if (validPassCheck) {
      if (passwordShow) {
        divInput.setAttribute('type', 'text')
        imageElement.src = passwordShowIcon
      }
      if (!passwordShow) {
        divInput.setAttribute('type', 'password')
        imageElement.src = passwordHideIcon
      }

      setPasswordShow((passwordShow) => !passwordShow)
    }
  }

  const OAuthStart = (evt) => {
    evt.preventDefault()

    evt.target.id === 'google' ? dbAPI.googleOAuth() : dbAPI.appleOAuth()
  }

  return (
    <div className="loginPage">
      <div className="loginPagenavBar">
        <div className="loginPagenavBarContents">
          <div className="loginPagelogo">
            <img className="loginPagenavbarLogo" src={Logo} />
          </div>
        </div>
        <div className="loginPagenavBarContets">
          <div className="loginPagesignUpArea">
            <p className="loginPageactionText">New to Darbe?</p>
            <button className="loginPagesignUpButton">Signup</button>
          </div>
        </div>
      </div>

      <div className="loginPageformContainer">
        <div className="loginPageloginModal">
          <div className="loginPagetitleLogin">
            <p>Log in</p>
          </div>

          <div className="loginPageerrorDisplay">
            {errors.length ? <Error errorMessages={errors} /> : ''}
          </div>

          <div className="loginPageloginForm">
            <form
              className="loginPageformPlacement verticalLayoutSignup"
              onSubmit={handleSubmit}
            >
              <div className="loginNoOAuth">
                <div className="loginPageemailInput">
                  <label htmlFor="email">Email</label>
                  <input
                    name="email"
                    id="email"
                    className=""
                    type="email"
                    placeholder="volunteer@email.com"
                    onChange={handleEvts}
                  ></input>
                </div>

                <div className="loginPagepasswordInput">
                  <label htmlFor="loginPagepassword">Password</label>
                  <input
                    id="password"
                    className=""
                    name="password"
                    type="password"
                    placeholder="● ● ● ● ● ● ● ●"
                    onChange={handleEvts}
                  ></input>
                  <img
                    onClick={changeIcons}
                    id="passwordIcon"
                    className="loginPagepasswordIcon"
                    src={passwordHideIcon}
                  />
                </div>

                <div className="loginPageresetLink">
                  <a href="/reset">Forgot Password?</a>
                </div>
              </div>

              <div className="signupPagelines">
                <img src={fancyLine} className="fancyLines"></img>
                <p className="wordBetweenLines"> or </p>
                <img src={fancyLine} className="fancyLines"></img>
              </div>

              <div className="signupPageOAUthButtonVertical">
                <img src={googleIcon} className="googleIcon"></img>
                <button
                  className="googleOAuthButton"
                  id="google"
                  onClick={OAuthStart}
                >
                  {' '}
                  Log in with Google
                </button>

                <img src={appleIcon} className="appleIcon"></img>
                <button
                  className="appleOAuthButton"
                  id="apple"
                  onClick={OAuthStart}
                >
                  {' '}
                  Log in with Apple
                </button>
              </div>

              <div className="loginPagesubmitButton">
                <button type="submit" className="loginPageloginButton">
                  Log In
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
