import React, { useEffect, useRef, useState } from 'react'
import { NavigationBar } from '../../components/navigation/NavigationBar'
import ChatFeed from './components/chatFeed'
import ChatInput from './components/chatInput'
import Conversation from './components/conversation'
import './index.css'

export const Messaging = () => {
  // this is just to test that the splits in the url are working properly.
  const name = 'keck'
  const id = '1832448'

  const [messageLog, updateMessageLog] = useState([])
  const [chatBox, updateChatBox] = useState({
    name: name,
    conversation: '',
    timeStamp: '',
    body: '',
  })
  // we need to use a reference so we're able to send messages
  // and manipulate data without having the connection fluctuate with the updates
  const socketRef = useRef()

  useEffect(() => {
    // pass our current connection to the useRef
    socketRef.current = new WebSocket(
      `ws://localhost:3001/websockets?${name}?${id}`
    )

    socketRef.current.onopen = () => {
      console.log('connected')
    }

    // when a message is received
    socketRef.current.onmessage = async ({ data }) => {
      const newMessage = JSON.parse(data)
      messageLogUpdate(newMessage)
    }

    // need to disconnect on the unmount process so our WebSocket isn't constantly running
    return () => {
      console.log('unmount')
      socketRef.current.close()
    }
  }, [socketRef])

  async function messageLogUpdate(info) {
    // this function is here cause updating the message log in use state tends to delete things previous.
    updateMessageLog((prevLog) => [info, ...prevLog])
  }

  async function sendMessage() {
    // convert to a json string for the server.
    const message = JSON.stringify(chatBox)
    // send message to the server using our reference of the socket.
    updateMessageLog([chatBox, ...messageLog])
    socketRef.current.send(message)
    // Clear the chatbox. will probably need to set this up differently.
    updateChatBox({
      name: name,
      conversation: '',
      timeStamp: '',
      body: '',
    })
  }

  return (
    <div>
      <NavigationBar />
      <div className="chatCont">
        <Conversation />
        <ChatFeed messageLog={messageLog} name={name} />
        <ChatInput
          chatBox={chatBox}
          updateChatBox={updateChatBox}
          sendMessage={sendMessage}
        />
      </div>
    </div>
  )
}
