import React, { createContext, useContext } from 'react'
import { useSignUpReducer } from './signupreducer.js'

// create context for the application state for user input.
const formContext = createContext()
const { Provider } = formContext

// Form info about the user signing up that will be sent to the back end.
// eslint-disable-next-line react/prop-types
export const FormProvider = ({ value = [], ...props }) => {
  const [state, dispatch] = useSignUpReducer({
    entity: '',
    name: '',
    email: '',
    password: '',
    city: '',
    zipCode: '',
    individualInfo: {
      dob: '',
      gender: '',
      availability: '',
    },
    causes: [],
  })
  // Return the provider so the application knows what to do with it.
  return <Provider value={[state, dispatch]} {...props} />
}

// export the form context.
export const useFormContext = () => {
  return useContext(formContext)
}
