import React from 'react'
import PopUpBox from '../PopUpBox'
import './style.css'

function CeoMessage() {
  return (
    <div className="container CeoMessageBg py-2 py-lg-5">
      <div className="text-center text-light MessageContainer">
        <h6 className="Houston">Houston Strong. Houston Inspired.</h6>
        <h2 className="beta">
          Beta Testing Will Launch Exclusively In Houston, Texas!
        </h2>
      </div>
      <div className="ceoSpace  text-center">
        <div className="mt-5">
          <h5 className="text-light ceoq" id="FoundersMessage">
            &quot;The small acts of kindness, charity, and goodwill are the
            greatest indicator of character in a person.&quot;
          </h5>
        </div>
        <div className="text-light ceoName">
          <p className="mb-0">-Richard-Sebastian Alexander</p>
          <p>Founder & CEO</p>
        </div>
      </div>
      <div className="Ceocta">
        <PopUpBox />
      </div>
    </div>
  )
}

export default CeoMessage
