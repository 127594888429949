import * as React from 'react'

const SvgLikeIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 9"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 9h1c.275 0 .5-.225.5-.5V4c0-.275-.225-.5-.5-.5H0V9Zm9.915-3.56a.99.99 0 0 0 .085-.4V4.5c0-.55-.45-1-1-1H6.25l.46-2.325a.501.501 0 0 0-.04-.33 2.4 2.4 0 0 0-.44-.61L6 0 2.795 3.205c-.19.19-.295.445-.295.71v3.92C2.5 8.475 3.025 9 3.67 9h4.055c.35 0 .68-.185.86-.485l1.33-3.075Z" />
  </svg>
)

export default SvgLikeIcon
