import React from 'react'
import PropTypes from 'prop-types'
import './CreatePostTextArea.css'

const CreatePostTextArea = ({
  postText,
  postImages,
  handleUpdateText,
  handleRemoveImageUpload,
}) => {
  const onTextAreaChange = (e) => {
    handleUpdateText(e)
    calculateHeight(e)
  }

  const calculateHeight = (e) => {
    // this defaults the height to smaller, then the next lines allow it to grow to the correct inner height up to 200px
    e.target.style.height = 'inherit'
    e.target.style.height = `${e.target.scrollHeight}px`
  }

  return (
    <div className="CreatePostTextAreaWrapper">
      <textarea
        autoFocus={true}
        className="CreatePostTextArea"
        value={postText}
        onChange={onTextAreaChange}
        placeholder="Write something..."
      />
      <div className="CreatePostImagesWrapper">
        {postImages.map((image, index) => {
          return (
            <div className="CreatePostImageUpload" key={index}>
              <img
                className="CreatePostImageUploadPic"
                src={image.url}
                alt={image.alt}
              />
              <div
                className="CreatePostRemoveImageUpload"
                onClick={() => handleRemoveImageUpload(index)}
              >
                X
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

CreatePostTextArea.propTypes = {
  postText: PropTypes.string,
  handleUpdateText: PropTypes.func,
  postImages: PropTypes.array,
  handleRemoveImageUpload: PropTypes.func,
}

export default CreatePostTextArea
