import * as React from 'react'

const SvgShareIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 10"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M.006 10 12 5 .006 0 0 3.889 8.571 5 0 6.111.006 10Z" />
  </svg>
)

export default SvgShareIcon
