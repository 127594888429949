import React from 'react'
import { FormProvider } from './assets/state/signupState.js'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import LandingPage from './pages/landing/index'
import PrivPol from './pages/privacyPolicy/index'
import Login from './pages/login/components/Login'
import NotFound from './pages/notfound'
import SignUp from './pages/signup/components/SignUp'
import searchResultsPage from './pages/searchResults/searchResultsPage'
import { UserNotifications } from './pages/notifications/notifications.js'
import { UserFeedHome } from './pages/userFeed/userFeed.js'
import { TestingPage } from './pages/index.js'
import { Messaging } from './pages/messaging/index'
import './App.css'
import CreatePost from './pages/createPost/index.js'

function App() {
  return (
    <Router>
      <FormProvider>
        <Switch>
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/privacyPolicy" component={PrivPol} />
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/searchResults" component={searchResultsPage} />
          <Route exact path="/createPost" component={CreatePost} />
          <Route
            path="/unsubscribe"
            render={({ location }) => {
              const searchString = location.search
              const email = searchString.split('=')
              return (
                <Redirect
                  to={{
                    pathname: '/',
                    state: {
                      unsub: 'true',
                      unsubEmail: email[2],
                    },
                  }}
                />
              )
            }}
          />
          <Route exact path="/test" component={TestingPage} />
          <Route exact path="/messaging" component={Messaging} />
          <Route exact path="/feed" component={UserFeedHome} />
          <Route
            exact
            path="/feed/:userId/notifications"
            component={UserNotifications}
          />
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </FormProvider>
    </Router>
  )
}

export default App
