import React, { useState } from 'react'
import { ProfilePicture } from '../User/ProfilePicture'
import { UserInformation } from '../User/UserInformation'
import { ContentType } from '../feedContent/ContentType'
import { PostInteraction } from '../../../components/buttons'
import { ShareModal } from '../../../components/shareModal/ShareModal'
import propTypes from 'prop-types'

export const FeedCard = ({
  identifier,
  contentType,
  textContent,
  photoURL,
  datePosted,
  likes,
  userLikedOwnPost,
}) => {
  // useEffect(() => {
  //   const getUserInfo = async () => {
  //     //get user info, profile, name, etc
  //   }
  // })

  const [showShareModal, setShowShareModal] = useState(false)
  class InteractionMethods {
    shareItem(evt) {
      evt.preventDefault()
      setShowShareModal(!showShareModal)
    }

    likesItem(evt) {
      evt.preventDefault()
      console.log('liking my post')
    }

    commentsItem(evt) {
      evt.preventDefault()
    }
  }

  const postInteractions = new InteractionMethods()

  return (
    <div className="feedCardOutline">
      <div className="feedCardTopLine">
        <div className="feedProfileComponent">
          <ProfilePicture />
        </div>
        <div className="feedUserInformationComponent">
          <UserInformation datePosted={datePosted} />
        </div>
      </div>
      <div className="feedContentTypeComponent">
        <ContentType
          identifier={identifier}
          contentType={contentType}
          textContent={textContent}
          photoURL={photoURL}
          datePosted={datePosted}
        />
      </div>
      <div className="feedContentInteractionComponents">
        <PostInteraction
          likes={likes}
          liked={userLikedOwnPost}
          handleClick={postInteractions}
        />
        {showShareModal && <ShareModal />}
      </div>
    </div>
  )
}

FeedCard.propTypes = {
  identifier: propTypes.string,
  contentType: propTypes.number,
  textContent: propTypes.string,
  photoURL: propTypes.array,
  datePosted: propTypes.instanceOf(Date),
  likes: propTypes.number,
  userLikedOwnPost: propTypes.bool,
}
