import React, { useEffect, useState } from 'react'
import { ProfilePicture } from '../../pages/feed/User/ProfilePicture'
import { SearchResults } from '../searchResults/SearchResults'
import { emailIcon, magnifyingGlass } from '../../assets'
import dbAPI from '../../util/dbAPI'
import './styles/SearchBarStyles.css'

export const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState()
  const [searchResults, setSearchResults] = useState()

  const handleEvts = (evt) => {
    const term = evt.target.value
    setSearchTerm(term)
  }

  useEffect(() => {
    const searchUsers = async () => {
      if (searchTerm !== undefined) {
        const listOfMatchingUsers = await dbAPI.searchUsers(searchTerm)
        if (listOfMatchingUsers) {
          setSearchResults(listOfMatchingUsers)
        }
      }
    }

    searchUsers()
  }, [searchTerm])

  return (
    <div className="searchBarComponent">
      <div className="searchBar">
        <ProfilePicture classValue="searchBarProfilePicture" />
        <div className="searchInputArea">
          <label htmlFor="search" className="searchBarLabel">
            <img className="magnifyingGlass" src={magnifyingGlass} />
            <span> | </span>
          </label>
          <input
            type="text"
            id="searchTerm"
            name="searchTerm"
            onChange={handleEvts}
            placeholder="Search Darbe"
          />
        </div>
        <img className="emailIconSearchBar" src={emailIcon} />
      </div>
      <div className="grayPlaceholder"></div>
      {searchResults && (
        <SearchResults searchResults={searchResults} searchTerm={searchTerm} />
      )}
    </div>
  )
}

export default SearchBar
