import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BACK_URL || 'http://localhost:3001'

// this is the main hook that will call all routes on darbeApi
// this should be used when fetching data for state management in individual components
// example GET request-> const [state, setState] = useROAsyncState([], url)
// this will return the response and place it in the state. Better practice than
// using useEffect when making async calls to api

const createDarbeBaseApi = async (method, url, data) => {
  return await axios({
    method: method,
    url: `${BASE_URL}/v1/api/${url}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  })
}

export const useDarbeApi = (method, url, data = {}) => {
  return createDarbeBaseApi(method, url, data)
}
