import * as React from 'react'

const SvgCommentIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 10"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9.995 1c0-.55-.445-1-.995-1H1C.45 0 0 .45 0 1v6c0 .55.45 1 1 1h7l2 2-.005-9Z" />
  </svg>
)

export default SvgCommentIcon
