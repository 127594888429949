import React from 'react'
import PropTypes from 'prop-types'
import './index.css'
import MessageRender from './_messageRender'

export default function ChatFeed({ messageLog, name }) {
  return (
    <section className="chatFeedCon">
      <MessageRender messageLog={messageLog} name={name} />
    </section>
  )
}

ChatFeed.propTypes = {
  messageLog: PropTypes.array,
  name: PropTypes.string,
}
