import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'

const CausesRowFive = ({ causes, userCauses, setUserCauses, entity }) => {
  const blueClassName = 'causesPickedBlue'
  const innerClassName = `signupPagecause signupPagecauseColumn `
  const comboName = innerClassName + blueClassName
  const [explicitClassName, setExplicitClassName] = useState(innerClassName)
  const [blueCauses, setBlueCauses] = useState([])

  const handleParentUpdate = (causeToUpdate, remove = false) => {
    if (remove) {
      setUserCauses((userCauses) => [
        ...userCauses.filter((cause) => cause !== causeToUpdate),
      ])
    }
    setUserCauses((userCauses) => [...userCauses, causeToUpdate])
  }

  const handleClicks = (evt) => {
    const causeClickedOn = evt.target.innerText.toString().split(' ')[0]

    if (
      (blueCauses.length >= 2 && entity) ||
      (userCauses.length >= 2 && entity)
    ) {
      if (blueCauses.includes(causeClickedOn)) {
        setBlueCauses((blueCauses) => [
          ...blueCauses.filter((cause) => cause !== causeClickedOn),
        ])
        handleParentUpdate(causeClickedOn, true)
        setExplicitClassName(comboName)
        return
      }
      return
    }
    if (!blueCauses.includes(causeClickedOn)) {
      setBlueCauses((blueCauses) => [...blueCauses, causeClickedOn])
      handleParentUpdate(causeClickedOn)
      setExplicitClassName(comboName)
      return
    }
    if (blueCauses.includes(causeClickedOn)) {
      setBlueCauses((blueCauses) => [
        ...blueCauses.filter((cause) => cause !== causeClickedOn),
      ])
      handleParentUpdate(causeClickedOn, true)
      setExplicitClassName(comboName)
    }
  }

  let displayCauses = causes.map((cause, idx) => {
    let whatClassName = innerClassName
    if (blueCauses.includes(cause.split(' ')[0])) {
      whatClassName = explicitClassName
    }

    const customClassName = `rowItem${idx} causesDiv ${cause} `
    const keyNum = idx * Math.random() * 1000 + 1 * Math.random() * 100
    return (
      <div key={keyNum} className={customClassName} onClick={handleClicks}>
        <div name={cause} className={whatClassName}>
          <p name={cause}>{cause}</p>
        </div>
      </div>
    )
  })

  useEffect(() => {
    displayCauses = causes.map((cause, idx) => {
      let whatClassName = innerClassName
      if (blueCauses.includes(cause.split(' ')[0])) {
        whatClassName = explicitClassName
      }

      const customClassName = `rowItem${idx} causesDiv ${cause} `
      const keyNum = idx * Math.random() * 1000 + 1 * Math.random() * 100
      return (
        <div key={keyNum} className={customClassName} onClick={handleClicks}>
          <div name={cause} className={whatClassName}>
            <p name={cause}>{cause}</p>
          </div>
        </div>
      )
    })
  }, [explicitClassName, setExplicitClassName])

  return <div className="rowFive">{displayCauses}</div>
}

CausesRowFive.propTypes = {
  causes: propTypes.array,
  userCauses: propTypes.array,
  setUserCauses: propTypes.func,
  entity: propTypes.string,
}

export default CausesRowFive
