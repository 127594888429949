import React from 'react'
import { Globe, Hands, HandShaking, Peace, World } from '../../../../assets'
import './style.css'

const cards = [
  {
    alt: 'Peace Icon',
    src: Peace,
    key: 1,
    Question: 'What is Darbe?',
    Answer:
      'We are a free social networking platform that connects volunteers, donors, and fundraisers to charities and nonprofits in their local communities. Our mission is to build a better way to do good!',
  },
  {
    alt: 'World Icon',
    src: World,
    key: 2,
    Question: 'How Does Darbe Make Philanthropy Better?',
    Answer:
      'We are the one-stop-shop for philanthropy. We leverage modern technology  on an engaging social platform. This allows volunteers, donors, nonprofits, and organizations to connect and create long lasting relationships!',
  },
  {
    alt: 'Hands Icon',
    src: Hands,
    key: 3,
    Question: 'Who Is Darbe For?',
    Answer:
      'For individuals, who want to connect with causes they are passionate about around their own schedule to create long lasting impact in their local community. \n For nonprofits, who want to be automatically connected with value driven individuals and organizations. This encourages high retention rates through strong engagement and brand awareness for nonprofits of all sizes. \n For organizations, of all sizes who want to be connected with highly impactful nonprofits on one social networking platform. This allows meaningful engagement with members and purposeful impact in their community.',
  },
  {
    alt: 'Globe Icon',
    src: Globe,
    key: 4,
    Question: 'Where Is Darbe Based In?',
    Answer:
      'info@darbe.co, We are based in and will be beta tested in Houston Texas. Any inquiries  from individuals/nonprofits/organizations can be emailed to:',
  },
  {
    alt: 'HandShake Icon',
    src: HandShaking,
    key: 5,
    Question: 'Will Darbe Cost Anything?',
    Answer: 'Our platform is 100% free to use!',
  },
]

function FaQ() {
  function Answers(Answer) {
    let i = 6
    const AnswersEdit = Answer.split('\n').map((Answer) => {
      const linksplit = Answer.split(',')
      const newAnswer = {
        Answer: linksplit[1].trim(),
        key: i,
        special: linksplit[0],
      }
      i++
      return newAnswer
    })

    console.log(AnswersEdit.length)

    if (AnswersEdit.length === 1) {
      console.log(AnswersEdit)
      return AnswersEdit.map((ans) => (
        <div key={ans.key}>
          <p className="blueBold">A:</p>
          <p className="answer tri">
            {ans.Answer}{' '}
            <a href={'mailto: ' + ans.special} className="underline">
              {ans.special}
            </a>
          </p>
        </div>
      ))
    } else {
      return AnswersEdit.map((Ans) => (
        <div key={Ans.key}>
          <p className="blueBold">A:</p>
          <p className="answer tri">
            {' '}
            <a href="" className="underline">
              {Ans.special}
            </a>{' '}
            {Ans.Answer}
          </p>
        </div>
      ))
    }
  }

  function IsEven(key) {
    switch (key) {
      case 1:
      case 3:
      case 5:
        return ' order-1 order-lg-3 offset-lg-1 offset-0 '
      default:
        return ' order-1 offset-lg-1'
    }
  }

  const altClass = 'card2 shadow col-lg-8 col-md-12 order-3'

  return (
    <div className="container sectionFaQ" id="FaQ">
      <div className="container">
        <h4 className="text-center msmall">We Are Here To Help</h4>
        <h3 className="text-center mbold">Frequently Asked Questions</h3>
        {cards.map((card) => (
          <div key={card.key} className="row">
            <div
              className={
                card.key === 2 || card.key === 4
                  ? altClass + ' offset-me '
                  : '' + altClass
              }
            >
              <p className="blueBold">Q:</p>
              <h5 className="question">{card.Question}</h5>
              {card.key === 3 || card.key === 4 ? (
                Answers(card.Answer)
              ) : (
                <>
                  <p className="blueBold">A:</p>{' '}
                  <p className="answer tri">{card.Answer}</p>
                </>
              )}
            </div>
            <img
              className={
                'col-lg-2 col-md-12 ' + IsEven(card.key) + ' ' + card.alt
              }
              src={card.src}
              alt={card.alt}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default FaQ
