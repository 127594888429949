import { useState } from 'react'

import { useDarbeApi } from './useDarbeApi'
import { useAsync } from './useAsync'

// this reads with GET requests. No body is sent in this request
// this uses a custom async hook that handles the request
// useDarbeApi is the main hook to make all calls to our api for state management

export default function useROAsyncState(defaultVal, url) {
  const [returnVal, setReturnVal] = useState(defaultVal)
  const [isLoading, setIsLoading] = useState(!!url)
  useAsync(async () => {
    if (!url) {
      return
    }
    setIsLoading(true)
    try {
      const readOnly = await useDarbeApi('GET', url)
      setReturnVal(readOnly.data)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }, [url])
  return [returnVal, isLoading]
}
