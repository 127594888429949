import React, { useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import './styles/notification.css'

import {
  NavigationBar,
  NavigationMenuMobile,
} from '../../components/navigation'

import { notificationTitleEnum, notificationTypesEnum } from './enum'

import { useSelector } from 'react-redux'
import useROAsyncState from '../../hooks/useROAsyncState'

import DefaultUser from './images/DefaultUser.jpg'
import { useDarbeApi } from '../../hooks/useDarbeApi'

const NotificationContext = createContext()

const NotificationCard = ({
  id,
  type,
  createdAt,
  user,
  senderId,
  postId,
  commentId = null,
}) => {
  const [senderInfo, senderInfoLoading] = useROAsyncState(
    [],
    `individual/${senderId}`
  )
  const [postInfo, postInfoLoading] = useROAsyncState([], `posts/${postId}`)

  const [commentInfo, commentInfoLoading] = useROAsyncState(
    [],
    `comments/${commentId}`
  )

  const { setShowModal } = useContext(NotificationContext)

  const [dropdown, setDropDown] = useState(false)
  const [expandText, setExpandText] = useState(false)

  function showText(el) {
    const textExpand = el.target.parentElement
    if (!expandText) {
      textExpand.style.webkitLineClamp = 'inherit'
      el.target.innerText = 'Close'
      setExpandText(true)
    } else {
      textExpand.style.webkitLineClamp = '2'
      el.target.innerText = 'see more'
      setExpandText(false)
    }
  }

  function truncatedText(text) {
    if (!expandText && text) {
      const truncText = text.split(' ')
      if (truncText.length >= 10) {
        return (
          <>
            {truncText.slice(0, 10).join(' ') + '...'}
            <a onClick={showText}>see more</a>
          </>
        )
      } else {
        return <>{truncText.slice(0, 10).join(' ')}</>
      }
    } else {
      return text
    }
  }

  function timeElapsed() {
    const dateSinceNotification = new Date(createdAt)
    const seconds = Math.floor((new Date() - dateSinceNotification) / 1000)

    let interval = seconds / 31536000

    if (interval > 1) {
      return Math.floor(interval) + ' years'
    }
    interval = seconds / 2592000
    if (interval > 1) {
      return Math.floor(interval) + ' months'
    }
    interval = seconds / 86400
    if (interval > 1) {
      return Math.floor(interval) + ' days'
    }
    interval = seconds / 3600
    if (interval > 1) {
      return Math.floor(interval) + ' hours'
    }
    interval = seconds / 60
    if (interval > 1) {
      return Math.floor(interval) + ' min'
    }
    return Math.floor(seconds) + ' seconds'
  }

  if (senderInfoLoading || postInfoLoading || commentInfoLoading) {
    return <></>
  }
  return (
    <div className="notification-card">
      <div className="row">
        <div className="col-1 col-md-3">
          <img
            id="notification-img"
            src={DefaultUser}
            className="img-fluid rounded-start"
            alt="..."
          />
          <img
            id="notification-type"
            src={notificationTypesEnum[type]}
            className="img-fluid rounded-start"
            alt="..."
          />
        </div>
        <div className="col-10 col-md-6">
          <div className="card-body">
            <div className="row" id="card-title-row">
              <p className="card-title" id="card-title">
                <b>{senderInfo.firstName}</b> {notificationTitleEnum[type]}
              </p>
            </div>
            <div className="row" id="card-text-row">
              <p className="card-text" id="card-text">
                {commentId != null
                  ? truncatedText(commentInfo.textContent)
                  : truncatedText(postInfo.textContent)}
              </p>
            </div>
          </div>
        </div>
        <div className="col-1 col-md-3">
          <div className="row">
            <p className="card-text" id="time">
              <small className="text-muted">{timeElapsed()}</small>
            </p>
          </div>
          <div className="row">
            <div className="dropdown">
              <button
                id="notification-dropdown-btn"
                onClick={() => setDropDown(!dropdown)}
                className="dropbtn"
              >
                ...
              </button>
              {dropdown && (
                <div id="notification-dropdown" className="dropdown-content">
                  <a>
                    <b>Report</b>
                  </a>
                  <a
                    onClick={() => {
                      setShowModal({
                        show: true,
                        notificationId: id,
                        type: type,
                        userId: user._id,
                        senderId: senderId,
                        postId: postId,
                      })
                      setDropDown(false)
                    }}
                  >
                    <b>Delete</b>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const DeleteModal = () => {
  const { showModal, setShowModal } = useContext(NotificationContext)

  async function removeNotification() {
    try {
      const removedNotification = await useDarbeApi(
        'DELETE',
        `notifications/delete/${showModal.type}`,
        {
          _id: showModal.notificationId,
          userId: showModal.userId,
          senderId: showModal.senderId,
          postId: showModal.postId,
        }
      )
      console.log(removedNotification)
    } catch (err) {
      console.log(err)
    }
    setShowModal({
      show: false,
      notificationId: '',
      type: '',
      userId: '',
      senderId: '',
      postId: '',
    })
  }

  return (
    <div className="modal-div-container">
      <div className="modal-div">
        <h4>Are you sure you want to delete?</h4>

        <div className="modal-btns-div">
          <button
            id="modal-btn-yes"
            color="primary"
            onClick={removeNotification}
          >
            Yes
          </button>{' '}
          <button
            id="modal-btn-cancel"
            onClick={() =>
              setShowModal({
                show: false,
                notificationId: '',
                type: '',
                userId: '',
                senderId: '',
                postId: '',
              })
            }
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

export const UserNotifications = () => {
  const { user } = useSelector((store) => store.userInfo)

  const [allNotifications, allNotificationsIsLoading] = useROAsyncState(
    [],
    `notifications/${user._id}`
  )

  const [showModal, setShowModal] = useState({
    show: false,
    notificationId: '',
    type: '',
    userId: '',
    senderId: '',
    postId: '',
  })

  return (
    <div className="notifications">
      <div className="row" id="nav-alone">
        <NavigationBar user={user} />
      </div>
      <div className="row" id="notification-stand-alone">
        <h4>
          <b>Notifications</b>
        </h4>
      </div>
      <div className="row notification-cards">
        <NotificationContext.Provider value={{ showModal, setShowModal }}>
          {showModal.show && <DeleteModal />}
          {!allNotificationsIsLoading && allNotifications && (
            <div>
              {allNotifications.map((el) => (
                <NotificationCard
                  key={el.id}
                  id={el._id}
                  type={el.type}
                  createdAt={el.createdAt}
                  user={user}
                  senderId={el.senderId}
                  postId={el.postId}
                  commentId={el?.commentId}
                />
              ))}
            </div>
          )}
        </NotificationContext.Provider>
      </div>
      <div className="row footer">
        <NavigationMenuMobile />
      </div>
    </div>
  )
}

NotificationCard.propTypes = {
  key: PropTypes.string,
  id: PropTypes.string,
  user: PropTypes.object,
  type: PropTypes.string,
  createdAt: PropTypes.string,
  senderId: PropTypes.string,
  postId: PropTypes.string,
  commentId: PropTypes.string,
}
