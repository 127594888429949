import React from 'react'
import { useHistory } from 'react-router-dom'

import Header from '../landing/components/Header'
import { image404 } from '../../assets'
import './style.css'

const NotFound = () => {
  const history = useHistory()

  const handleGoBackClick = () => {
    history.push('/')
  }
  return (
    <>
      <Header />

      <img src={image404} className="image404" />

      <div className="titleText">You fell off the map...</div>

      <div className="subTitleText">We can’t seem to find that page!</div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <button
          className={`btn text-uppercase text-light`}
          onClick={handleGoBackClick}
        >
          go home
        </button>
      </div>

      <div className="footerText">
        Have questions or concerns? You can reach us at{' '}
        <a className="mailto" href="mailto: info@darbe.co">
          info@darbe.co
        </a>
      </div>
    </>
  )
}

export default NotFound
