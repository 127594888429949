import React from 'react'
import Warning from '../../../assets/img/warning.png'

const Error = (errorMessages) => {
  // Uncomment when we want to add custom errors
  // let display = errorMessages.map((err, idx) => {
  //     return (<p key={idx} className='d-flex justify-content-center'>{err}</p>)
  // })
  const message = errorMessages.errorMessages[0]

  return (
    <div className="loginPagewarningMessage">
      <img className="loginPageerrorIcon" src={Warning} alt="error icon"></img>
      {/* {display} */}
      <p>{message}</p>
    </div>
  )
}

export default Error
