import * as React from 'react'

const SvgEvents = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 31 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M27.168 6.25h-5.833V3.168c0-1.71-1.298-3.083-2.917-3.083h-5.833c-1.62 0-2.917 1.372-2.917 3.083v3.084H3.835C2.215 6.25.933 7.623.933 9.334L.918 26.292c0 1.712 1.298 3.084 2.917 3.084h23.333c1.619 0 2.917-1.372 2.917-3.084V9.334c0-1.711-1.298-3.083-2.917-3.083Zm-8.75 0h-5.833V3.168h5.833v3.084Z"
    />
  </svg>
)

export default SvgEvents
