// Navigation menu component for mobile / tablet devices

import React, { useState } from 'react'

// Styling
import './NavigationMenuMobile.css'

// Nav menu mobile icons
import { Home, Events, Post, Match, Impact } from './IconComponents'

const NavigationMenuMobile = () => {
  const [activeSelectorIdx, setActiveSelectorIdx] = useState(0)

  const navSelections = [
    {
      IconComponent: <Home />,
      text: 'Home',
      link: '',
    },
    {
      IconComponent: <Events />,
      text: 'Events',
      link: '',
    },
    {
      IconComponent: <Post />,
      text: 'Post',
      link: 'createPost',
    },
    {
      IconComponent: <Match />,
      text: 'Match',
      link: '',
    },
    {
      IconComponent: <Impact />,
      text: 'Impact',
      link: '',
    },
  ]

  return (
    <div className="nav-menu-mobile">
      {/* Create nav menu from navSelections */}
      {navSelections.map((navSelection, idx) => {
        const { IconComponent, text } = navSelection
        return (
          <div
            onClick={(evt) => {
              evt.preventDefault()
              setActiveSelectorIdx(idx)
            }}
            key={idx}
            className={`nav-menu-mobile-item ${
              idx === activeSelectorIdx ? 'nav-menu-mobile-item-active' : ''
            }`}
          >
            {IconComponent}
            <p>{text}</p>
          </div>
        )
      })}
    </div>
  )
}

export default NavigationMenuMobile
